.global--center {
  text-align: center;
}

.global--button {
  display: inline-block;
  font-family: $font-main;
  font-weight: 900;
  font-size: 15px;
  text-transform: uppercase;
  border-radius: 6px;
  position: relative;
  margin-top: 10px;
  background-color: $domain-color;
  color: #fff;
  padding-left: 10px;
  transition: background-color 0.2s ease-in;
  align-self: flex-end;
  position: relative;
  backface-visibility: hidden;
  border: 1px solid $domain-color;
  padding: 0 90px;
  padding-left: 10px;
  line-height: 30px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 9px;
    height: 13px;
    background-image: url('https://ikea-2020.wpcdn.pl/img/arrow.png');
    top: 50%;
    right: 8%;
    transform: translateY(-50%);
    transition: opacity 0.2s ease;
  }

  @include max-breakpoint ($point2) {
    font-size: 14px;
  }

  @include max-breakpoint($point3) {
    padding-right: 38px;
  }

  &:hover {
    background-color: transparent;
    color: $domain-color;
    border: 1px solid $domain-color;
  }

  @include max-breakpoint ($point1) {}
}

@keyframes flicker {
  0% {
    transform: rotate(-1deg);
    filter: blur(0px);
  }

  20% {
    transform: rotate(1deg);
    filter: blur(1px);
  }

  40% {
    transform: rotate(-1deg);
    filter: blur(0px);
  }

  60% {
    transform: rotate(1deg) scaleY(1.04);
    filter: blur(1px);
  }

  80% {
    transform: rotate(-2deg) scaleY(0.92);
    filter: blur(0px);
  }

  100% {
    transform: rotate(1deg);
    filter: blur(1px);
  }
}