.footer {
  font-size: 1.4rem;
  position: relative;
  width: 100%;
  margin: 0 auto 0;
  background-size: cover;
  color: white;
  background-image: url(https://wpartner-2020.wpcdn.pl/img/footer-bg.jpg);

  .wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    padding-top: 28px;
    max-width: $layoutWidth;
    flex-direction: column;
    // background-color: #fff;

    &:after {
      display: none;
    }

    @include max-breakpoint($layoutWidth) {
      padding: 70px 20px;
      padding-bottom: 50px;
    }

    @include max-breakpoint($point1) {
      flex-wrap: wrap;
    }

    @include max-breakpoint(420px) {
      flex-direction: column;
    }

    .footer-title {
      font-size: 48px;
      font-family: 'Jost', sans-serif;
      margin-top: 30px;
      margin-bottom: 40px;
      text-align: center;

      @include max-breakpoint(780px) {
        font-size: 40px;
      }
    }

    .footer-info {
      display: flex;
      width: 750px;
      justify-content: space-evenly;
      flex-wrap: wrap;
      max-width: 100%;

      address {
        font-size: 39px;
        font-style: normal;
        font-family: Jost, sans-serif;

        @include max-breakpoint(400px) {
          font-size: 29px;
        }
    }

      .person {
        text-align: center;
        margin: 0 8px;
        margin-bottom: 30px;

        p {
          line-height: 28px;
          font-size: 18px;

          &:nth-of-type(1) {
            font-weight: 600;
          }
        }

        img {
          width: 140px;
          height: 140px;
          border-radius: 50%;
          object-fit: cover;
          object-position: center top;
        }
      }
    }

    .footer--logo {}

    .footer-content {
      width: 60%;
      margin: 0 auto;
      margin-top: 40px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      line-height: 1.4;
      color: #2a237f;

      @include max-breakpoint($point1) {
        width: 100%;
        order: 1;
        padding: 10px 0 0;
        margin-bottom: 20px;
      }

      p {
        line-height: 1.4;

        // strong {
        //   font-weight: normal;
        // }
      }
    }

    .logo--patron {
      display: flex;
      flex-direction: column;
      // flex-direction: column;

      @include max-breakpoint(420px) {
        padding: 14px 0 0;
      }

      p {
        color: white;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 1px;
        margin-bottom: 10px;
      }


      .logo--desc {
        color: #000;
        font-size: 14px;
        text-transform: uppercase;
        max-width: 70px;
        margin-right: 20px;
        font-weight: 400;
        margin-right: 20px;
        color: #75604e;

        @include max-breakpoint($point2) {
          font-size: 12px;
          margin-right: 0;
        }
      }

      img {
        @include max-breakpoint($point2) {
          width: 100px;
        }
      }

      // .img {
      //   display: flex;
      //   align-items: center;

      //   img {
      //     margin-left: 10px;
      //   }
      // }
    }
  }
}

.footer--logo {
  margin-right: 80px;

  @include max-breakpoint($point2) {
    margin-right: 0;
  }
}

.menu--footer {
  height: 40px;
  line-height: 40px;
  background-color: #fff;

  @include max-breakpoint(720px) {
    display: none;
  }

  .footer--nav {
    background-color: $domain-color;
    width: 70%;
    position: relative;

    @include max-breakpoint(950px) {
      width: calc(100% - 110px);
    }

    ul {
      display: flex;
      justify-content: space-evenly;

      li {
        h2 {
          text-transform: uppercase;
          font-size: 16px;
        }
      }

      .social {
        position: absolute;
        height: 100%;
        cursor: pointer;

        &.fb {
          right: -30px;
          top: 5px;

          &:before {
            content: "";
            display: block;
            position: absolute;
            width: 11px;
            height: 20px;
            left: 50%;
            top: 5px;
            transform: translate(-50%, 0%);
          }

          &:hover:before {
            // content: url("https://opel-2020.wpcdn.pl/img/fb-hover.png");
            background-image: url(https://opel-2020.wpcdn.pl/img/fb-hover.png);
          }

          &:hover {
            img {
              opacity: 0;
            }
          }
        }

        &.tw {
          right: -80px;
          top: 5px;

          &:before {
            content: "";
            display: block;
            position: absolute;
            width: 28px;
            height: 21px;
            left: 50%;
            top: 4px;
            transform: translate(-50%, 0%);
          }

          &:hover:before {
            // content: url("https://opel-2020.wpcdn.pl/img/fb-hover.png");
            background-image: url(https://opel-2020.wpcdn.pl/img/yt-hover.png);
          }

          &:hover {
            img {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}
.footer-links {
    padding-top: 2vw;
    text-align: center;
    a {
        color: #fff;
        display: block;
        margin: 0 auto;
        padding: .6vw 0;
    }
}