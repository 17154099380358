html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, big, em, img, q,
abbr, address, cite, code, del, dfn, em, img, ins, samp,
small, strong, tt, var, b, u, i, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, figure,
table, caption, tbody, tfoot, thead, tr, th, td,
time, mark, audio, video, tbody { border:0; margin:0; outline:0; padding:0; vertical-align:baseline;}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section { display:block }
ul { list-style: none}
a { text-decoration: none; color: #000;}
