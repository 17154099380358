
.opening-text img {
    width: 545px;
    max-width: 90%;
}


.section-wrapper.icons {

    .wrapper {

        .container {
            margin-top: 60px;

            .element {
                display: flex;
                width: 70%;
                margin: 0 auto;
                justify-content: space-around;
                margin-bottom: 50px;
                overflow: hidden;

                @include max-breakpoint(980px) {
                    width: 80%;
                }

                @include max-breakpoint(785px) {
                    width: 90%;
                }

                @include max-breakpoint(705px) {
                    width: 100%;
                }

                @include max-breakpoint(505px) {
                    flex-wrap: wrap;
                }

                &:nth-last-child(1) {
                    margin-bottom: 0;
                }

                &:nth-of-type(2n) {

                    .img {
                        text-align: left;
                        transition: .5s;
                        order: -1;

                        @include max-breakpoint(505px) {
                            text-align: center;
                            order: 1;
                        }
                    }
                }

                .text {
                    width: 48%;
                    color: #343440;
                    position: relative;

                    @include max-breakpoint(505px) {
                        width: 100%;
                    }

                    .content {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);

                        @include max-breakpoint(505px) {
                            position: static;
                            transform: unset;
                        }

                        h3 {
                            font-weight: 500;
                            font-size: 30px;
                            display: inline-grid;

                            @include max-breakpoint(860px) {
                                font-size: 26px;
                            }

                            @include max-breakpoint(640px) {
                                font-size: 21px;
                            }

                            span {
                                display: inline-block;
                                font-weight: 700;
                                position: relative;
                                font-family: $font-title;
                                border-bottom: 6px solid #5546ff;
                                line-height: 28px;

                                // &:before {
                                //     content: "";
                                //     display: block;
                                //     position: absolute;
                                //     width: 100%;
                                //     height: 6px;
                                //     background-color: #5546ff;
                                //     left: 0;
                                //     bottom: 5px;
                                // }
                            }
                        }

                        p {
                            margin-top: 20px;
                            color: #848497;
                        }
                    }
                }

                .img {
                    // width: 48%;
                    width: 380px;
                    height: 380px;
                    text-align: right;
                    transform: translateY(-120%);
                    transition: .7s;
                    margin: -40px 0;
                    opacity: 0;


                    @include max-breakpoint(1030px) {
                        width: 350px;
                        height: 350px;
                    }

                    @include max-breakpoint(560px) {
                        width: 50%;
                    }

                    @include max-breakpoint(505px) {
                        width: 100%;
                        text-align: center;
                        margin: -50px;
                    }

                    @include max-breakpoint(360px) {
                        margin: -60px;
                    }

                    img {
                        max-width: 100%;
                    }
                }

                &.__js_inView {
                    .img {
                        transform: translate(0);
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.icon1 {
    .st0 {
        fill: #CCC8FF;
        animation: wave 5s ease-in-out infinite;
    }

    .st1 {
        fill: #FAFAFC;
    }

    .st2 {
        fill: #E9E9F3;
    }

    .st3 {
        fill: #5546FF;
    }

    .st4 {
        fill: #7E7E92;
    }

    .st5 {
        fill: #E5E5E9;
    }

    .st6 {
        fill: #EEECFF;
    }

    .st7 {
        fill: #FFFFFF;
    }

    path.diagram {
        animation: rotate 8s 2 linear;
        transform-origin: 643px 383px;
    }

    .circle {
        transition: .5s;
        animation: opacity 3s infinite ease-in-out;
    }

    .circle-blue {
        transform-origin: 450px 110px;
        animation: circle-blue infinite 6s ease-in-out;
    }
}

.icon2 {
    .st0 {
        fill: #E9E9F3;
    }

    .st1 {
        fill: #E5E5E9;
    }

    .st2 {
        fill: #FAFAFC;
    }

    .st3 {
        fill: #5546FF;
    }

    .st4 {
        fill: #7E7E92;
    }

    .st5 {
        fill: #FFFFFF;
    }

    .bar-1 {
        animation: barBefore 6s linear infinite;
        fill: #5546ff;
    }

    .bar-2 {
        animation: bar 6s linear infinite;
        fill: #5546ff;
    }

    .bar-3 {
        animation: barAfter 6s linear infinite;
        fill: #5546ff;
    }

    .triangle-blue {
        transform-origin: 139px 470px;
        animation: triangle-blue 10s infinite alternate linear;
    }
}

.icon3 {
    .st0 {
        fill: #FAFAFC;
    }

    .st1 {
        fill: #E9E9F3;
    }

    .st2 {
        fill: #EEECFF;
    }

    .st3 {
        fill: #5546FF;
    }

    .st4 {
        fill: #E5E5E9;
    }

    .st5 {
        fill: #7E7E92;
    }

    .st6 {
        fill: #FFFFFF;
        // animation-name: dots;
        // animation-iteration-count: 3;
        // animation-duration: 5s;
        // animation-timing-function: ease-in-out;
    }

    .st7 {
        fill: #B7B7CC;
    }

    .arrow {
        animation: arrow 5s infinite ease-in-out;
        // animation-iteration-count: 3;
        // animation-duration: 5s;
        // animation-timing-function: ease-in-out;
    }

    .line {
        animation: arrow-line 5s infinite ease-in-out;
        // animation-iteration-count: 3;
        // animation-duration: 5s;
        // animation-timing-function: ease-in-out;
    }

    .bar {
        animation: arrow-bar 5s infinite ease-in-out;
    }

    .st7.bar {
        animation: arrow-bar-smaller 5s infinite ease-in-out;
    }

    .small-circle {
        transform-origin: 400px 400px;
        animation: rotate-small 15s linear infinite;
    }

    .small-triangle {
        transform-origin: 400px 400px;
        animation: rotate-small 20s linear reverse infinite;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes opacity {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes transparent {
    0% {
        fill: transparent;
    }

    25% {
        fill: #fff;
    }

    75% {
        fill: #fff;
    }

    100% {
        fill: transparent;
    }
}

@keyframes dots {
    0% {
        fill: #fff;
    }

    25% {
        fill: #fff;
    }

    50% {
        fill: #5546ff;
    }

    75% {
        fill: #5546ff;
    }

    100% {
        fill: #fff;
    }
}

@keyframes arrow {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-100px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes arrow-line {
    0% {
        transform: scaleY(1);
    }

    50% {
        transform: translateY(-325px) scaleY(1.5);
    }

    100% {
        transform: translateY(0) scaleY(1);
    }
}

@keyframes arrow-bar {
    0% {
        transform: scaleY(1);
    }

    50% {
        transform: translateY(-325px) scaleY(1.5);
    }

    100% {
        transform: translateY(0) scaleY(1);
    }
}

@keyframes arrow-bar-smaller {
    0% {
        transform: scaleY(1);
    }

    50% {
        transform: translateY(-193.8px) scaleY(1.3);
    }

    100% {
        transform: translateY(0) scaleY(1);
    }
}

@keyframes wave {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(20px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes barBefore {
    0% {
        fill: #7e7e92;
        height: 57px;
        transform: translateY(0);
    }

    25% {
        fill: #5546ff;
        height: 87px;
        transform: translateY(-30px);
    }

    50% {
        fill: #5546ff;
        height: 87px;
        transform: translateY(-30px);
    }

    75% {
        fill: #5546ff;
        height: 87px;
        transform: translateY(-30px);
    }

    100% {
        fill: #7e7e92;
        height: 57px;
        transform: translateY(0);
    }
}

@keyframes bar {
    0% {
        fill: #7e7e92;
    }

    25% {
        fill: #7e7e92;
        height: 104.8px;
        transform: translateY(0);
    }

    50% {
        fill: #5546ff;
        height: 134.8px;
        transform: translateY(-30px);
    }

    75% {
        fill: #5546ff;
        height: 134.8px;
        transform: translateY(-30px);
    }

    100% {
        fill: #7e7e92;
        height: 104.8px;
        transform: translateY(0);
    }
}

@keyframes barAfter {
    0% {
        fill: #7e7e92;
    }

    25% {
        fill: #7e7e92;
    }

    50% {
        fill: #7e7e92;
        height: 208px;
        transform: translateY(0);
    }

    75% {
        fill: #5546ff;
        height: 228px;
        transform: translateY(-20px);
    }

    100% {
        fill: #7e7e92;
        height: 208px;
        transform: translateY(0);
    }
}

@keyframes rotate-small {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes circle-blue {
    0% {
        transform: translate(0);
    }

    50% {
        transform: translate(100px, 80px);
    }

    100% {
        transform: translate(0);
    }
}

@keyframes triangle-blue {
    0% {
        transform: translate(0);
    }

    30% {
        transform: translate(0, 112px);
    }

    100% {
        transform: translate(340px, 112px);
    }
}