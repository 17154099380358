@import url("https://fonts.googleapis.com/css2?family=Varta:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,700;0,900;1,600&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, big, em, img, q,
abbr, address, cite, code, del, dfn, em, img, ins, samp,
small, strong, tt, var, b, u, i, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, figure,
table, caption, tbody, tfoot, thead, tr, th, td,
time, mark, audio, video, tbody {
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block; }

ul {
  list-style: none; }

a {
  text-decoration: none;
  color: #000; }

/*body*/
html {
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden; }

body {
  font-family: "Varta", sans-serif;
  font-size: 1.4rem;
  width: 100%;
  overflow-x: hidden;
  color: #fff;
  background-color: #fff;
  background-size: cover;
  margin-top: 90px; }
  @media screen and (max-width: 760px) {
    body {
      margin-top: 60px;
      background-position: 50% 60px; } }

scrollbar {
  width: 8px;
  background: rgba(119, 119, 119, 0.1); }

scrollbar-track {
  background-color: rgba(5, 5, 5, 0.1);
  border-radius: 0px; }

scrollbar-thumb {
  border-radius: 1px;
  background: #5546ff; }

::-webkit-scrollbar {
  width: 8px;
  background: rgba(119, 119, 119, 0.1); }

::-webkit-scrollbar-track {
  background-color: rgba(5, 5, 5, 0.1);
  border-radius: 0px; }

::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background: #5546ff; }

.outer {
  position: relative;
  overflow: hidden;
  padding: 0 3%; }
  @media screen and (max-width: 1274px) {
    .outer {
      margin-top: 0; } }
  @media screen and (max-width: 888px) {
    .outer {
      padding: 0 4%; } }

.wrapper {
  margin: 0px auto;
  position: relative;
  box-sizing: border-box; }
  .wrapper .section-products-title {
    margin-bottom: 25px;
    font-weight: 900;
    font-size: 50px;
    color: #0058a3;
    text-align: center;
    font-family: "Jost", sans-serif;
    font-weight: normal; }
    @media screen and (max-width: 640px) {
      .wrapper .section-products-title {
        font-size: 40px; } }

.section-title {
  text-transform: uppercase;
  margin-bottom: 25px;
  font-weight: 900;
  font-size: 50px;
  color: #000;
  font-family: "casablanca-urw"; }

.hr-desc {
  padding: 0 !important;
  font-size: 14px;
  color: #222222;
  border-top: 3px solid black;
  text-transform: uppercase;
  margin-bottom: -5px; }

.section-wrapper:nth-of-type(n) {
  background-color: #fff; }

.section-wrapper:nth-of-type(2n) {
  background-color: #efeef6; }

.section-wrapper .wrapper {
  max-width: 1260px;
  margin: 0 auto;
  padding: 70px 20px; }
  @media screen and (max-width: 500px) {
    .section-wrapper .wrapper {
      padding: 50px 20px; } }
  .section-wrapper .wrapper .title {
    font-size: 30px;
    color: #343440;
    text-align: center;
    font-weight: 500;
    font-family: "Jost", sans-serif; }
    @media screen and (max-width: 450px) {
      .section-wrapper .wrapper .title {
        font-size: 26px; } }
    @media screen and (max-width: 380px) {
      .section-wrapper .wrapper .title {
        font-size: 24px; } }
    .section-wrapper .wrapper .title.bold {
      font-weight: 600; }
    .section-wrapper .wrapper .title span {
      font-weight: 700; }

.global--center {
  text-align: center; }

.global--button {
  display: inline-block;
  font-family: "Varta", sans-serif;
  font-weight: 900;
  font-size: 15px;
  text-transform: uppercase;
  border-radius: 6px;
  position: relative;
  margin-top: 10px;
  background-color: #5546ff;
  color: #fff;
  padding-left: 10px;
  transition: background-color 0.2s ease-in;
  align-self: flex-end;
  position: relative;
  backface-visibility: hidden;
  border: 1px solid #5546ff;
  padding: 0 90px;
  padding-left: 10px;
  line-height: 30px; }
  .global--button:after {
    content: "";
    display: block;
    position: absolute;
    width: 9px;
    height: 13px;
    background-image: url("https://ikea-2020.wpcdn.pl/img/arrow.png");
    top: 50%;
    right: 8%;
    transform: translateY(-50%);
    transition: opacity 0.2s ease; }
  @media screen and (max-width: 768px) {
    .global--button {
      font-size: 14px; } }
  @media screen and (max-width: 640px) {
    .global--button {
      padding-right: 38px; } }
  .global--button:hover {
    background-color: transparent;
    color: #5546ff;
    border: 1px solid #5546ff; }

@keyframes flicker {
  0% {
    transform: rotate(-1deg);
    filter: blur(0px); }
  20% {
    transform: rotate(1deg);
    filter: blur(1px); }
  40% {
    transform: rotate(-1deg);
    filter: blur(0px); }
  60% {
    transform: rotate(1deg) scaleY(1.04);
    filter: blur(1px); }
  80% {
    transform: rotate(-2deg) scaleY(0.92);
    filter: blur(0px); }
  100% {
    transform: rotate(1deg);
    filter: blur(1px); } }

.header {
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  height: 90px;
  border: 0;
  outline: 0;
  z-index: 7;
  border-bottom: 1px solid #848497; }
  @media screen and (max-width: 760px) {
    .header {
      height: 60px; } }
  .header > .wrapper {
    max-width: 1730px;
    box-sizing: border-box;
    margin: 0 auto;
    height: 100%;
    position: static; }
  .header nav.header--nav {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    width: calc(92vw - 280px);
    max-width: 1440px; }
    .header nav.header--nav ul {
      display: flex;
      height: 100%;
      width: 100%; }
      @media screen and (max-width: 760px) {
        .header nav.header--nav ul {
          flex-direction: column;
          width: 100%; } }
      .header nav.header--nav ul li:nth-last-of-type(1) {
        margin-left: auto;
        color: #d1d1dd; }
        @media screen and (max-width: 760px) {
          .header nav.header--nav ul li:nth-last-of-type(1) {
            margin-left: 0;
            color: transparent; } }
        @media screen and (max-width: 760px) {
          .header nav.header--nav ul li:nth-last-of-type(1) span {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #4037b0; } }
        @media screen and (max-width: 760px) {
          .header nav.header--nav ul li:nth-last-of-type(1) span a {
            background-color: transparent;
            width: 50%; } }
        @media screen and (max-width: 760px) {
          .header nav.header--nav ul li:nth-last-of-type(1) span a:hover {
            background-color: #fff; } }
        .header nav.header--nav ul li:nth-last-of-type(1) span a.__active:hover {
          cursor: unset; }
          @media screen and (max-width: 760px) {
            .header nav.header--nav ul li:nth-last-of-type(1) span a.__active:hover {
              background-color: transparent; } }
          .header nav.header--nav ul li:nth-last-of-type(1) span a.__active:hover:before {
            display: none; }
        .header nav.header--nav ul li:nth-last-of-type(1) span a.__active h2 {
          color: #d1d1dd; }
          @media screen and (max-width: 760px) {
            .header nav.header--nav ul li:nth-last-of-type(1) span a.__active h2 {
              color: #241f55; } }
        .header nav.header--nav ul li:nth-last-of-type(1) span a h2 {
          font-size: 13px; }
      .header nav.header--nav ul li span {
        height: 100%;
        display: block; }
        .header nav.header--nav ul li span a {
          height: 100%; }
          .header nav.header--nav ul li span a h2 {
            font-size: 14px;
            color: #7e7e92;
            transition: .3s; }
            @media screen and (max-width: 760px) {
              .header nav.header--nav ul li span a h2 {
                line-height: normal;
                padding: 10px;
                color: #fff; } }
          .header nav.header--nav ul li span a:before {
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            bottom: 2px;
            background-color: #5546ff;
            width: 0;
            height: 2px;
            transition: .3s;
            transform: translateX(-50%);
            opacity: 0; }
    .header nav.header--nav a {
      display: inline-block;
      vertical-align: middle;
      font-size: 20px;
      font-weight: bold;
      position: relative;
      box-sizing: border-box;
      z-index: 2;
      color: #2d2d2d;
      transition: all 170ms ease;
      margin: 0 10px;
      text-transform: uppercase; }
      .header nav.header--nav a:hover::before {
        background-color: #5546ff;
        width: 100%;
        opacity: 1; }
        @media screen and (max-width: 760px) {
          .header nav.header--nav a:hover::before {
            width: 0; } }
      .header nav.header--nav a:hover h2 {
        color: #3b3b3a; }
        @media screen and (max-width: 760px) {
          .header nav.header--nav a:hover h2 {
            color: #fff; } }
      @media screen and (max-width: 760px) {
        .header nav.header--nav a:hover {
          border: none; } }
      .header nav.header--nav a.__active:before {
        opacity: 1;
        transition: opacity 0.2s ease; }
      .header nav.header--nav a p {
        position: relative; }

.header-row {
  height: 90px;
  width: 100%;
  max-width: 1920px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
  min-width: 180px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: white; }
  @media screen and (max-width: 760px) {
    .header-row {
      height: 60px; } }
  @media screen and (max-width: 1920px) {
    .header-row {
      padding: 0 2% 0 0; } }
  @media screen and (max-width: 760px) {
    .header-row {
      justify-content: space-between; } }
  .header-row a.header--logo {
    border: 0;
    z-index: 1;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 2%;
    padding-right: 2%;
    position: relative;
    overflow: hidden; }
    @media screen and (max-width: 1250px) {
      .header-row a.header--logo {
        margin-right: 5px; } }
    @media screen and (max-width: 480px) {
      .header-row a.header--logo {
        margin-right: 0; } }
    .header-row a.header--logo:hover {
      border: 0; }
  .header-row a.logo--patron {
    border: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    @media screen and (max-width: 480px) {
      .header-row a.logo--patron {
        flex-direction: column;
        height: 100%;
        justify-content: space-around;
        align-items: flex-start; } }
    .header-row a.logo--patron .logo--desc {
      color: #000;
      font-size: 12px;
      text-transform: uppercase;
      padding-right: 8px;
      letter-spacing: 1px; }
      @media screen and (max-width: 480px) {
        .header-row a.logo--patron .logo--desc {
          font-size: 11px; } }
    @media screen and (max-width: 480px) {
      .header-row a.logo--patron > img {
        max-height: 60%; } }

.header-hamburger {
  display: none; }
  .header-hamburger span {
    background-color: #5546ff;
    border-radius: 2px;
    width: 30px;
    height: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .header-hamburger span:nth-of-type(1) {
      top: calc(50% - 10px); }
    .header-hamburger span:nth-of-type(3) {
      top: calc(50% + 10px); }

@media screen and (max-width: 760px) {
  .header .wrapper {
    position: static; }
  .header .header-row {
    padding: 0 10px 0 0; }
    .header .header-row .header--logo img {
      height: 100%;
      object-fit: contain;
      width: 90%;
      max-height: 50%; } }
    @media screen and (max-width: 760px) and (max-width: 500px) {
      .header .header-row .header--logo img {
        width: 90%; } }
    @media screen and (max-width: 760px) and (max-width: 390px) {
      .header .header-row .header--logo img {
        width: 110px; } }

@media screen and (max-width: 760px) {
  .header .header-hamburger {
    display: block;
    position: absolute;
    clear: both;
    content: " ";
    background-size: 100%;
    top: 50%;
    transform: translateY(-50%);
    right: 140px;
    width: 60px;
    border-radius: 7px;
    height: 70%;
    cursor: pointer;
    z-index: 2;
    transition: all .62s ease; } }
  @media screen and (max-width: 760px) and (max-width: 430px) {
    .header .header-hamburger {
      right: 125px; } }

@media screen and (max-width: 760px) {
    .header .header-hamburger img {
      width: 30%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
  .header.__shrinked .header-hamburger {
    right: 16px; }
  .header.__shrinked nav.header--nav {
    width: calc(92vw - 170px); }
  .header .header-hamburger.__js_isActive {
    display: block; }
    .header .header-hamburger.__js_isActive img {
      width: 30%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
  .header nav.header--nav {
    position: absolute;
    right: -100%;
    top: 100%;
    width: 33%;
    min-width: 270px;
    overflow: hidden;
    height: auto;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s ease;
    will-change: right, opacity; } }
  @media screen and (max-width: 760px) and (max-width: 600px) {
    .header nav.header--nav {
      width: 100%; } }

@media screen and (max-width: 760px) {
  .header nav.header--nav.__js_isActive {
    opacity: 1;
    text-align: center;
    right: 0; }
  .header nav.header--nav {
    flex-direction: column;
    text-align: center; }
    .header nav.header--nav a {
      margin: 0 auto;
      display: block;
      clear: both;
      color: black;
      padding: 0;
      position: relative;
      background-color: #5546ff; }
      .header nav.header--nav a:hover {
        background-color: #efeef6; }
        .header nav.header--nav a:hover h2 {
          color: #5546e0; }
        .header nav.header--nav a:hover:before {
          display: block; }
      .header nav.header--nav a.__active {
        border-bottom: 0; }
    .header nav.header--nav a.logo--patron {
      display: block;
      padding: 0;
      top: 0;
      padding-top: 4%;
      box-sizing: content-box;
      position: relative;
      margin-bottom: 3%;
      border-bottom: 0;
      float: none; }
  .header.active nav.header--nav {
    right: 0;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); } }

@media screen and (max-width: 768px) {
  .header .wrapper nav.header--nav {
    flex-direction: column; } }
  @media screen and (max-width: 768px) and (orientation: landscape) {
    .header .wrapper nav.header--nav {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      align-content: stretch;
      max-width: 100%;
      width: 100%; }
      .header .wrapper nav.header--nav a:before {
        width: 100%;
        left: 0;
        transform: none;
        bottom: 0; } }

.header--logo-main {
  display: flex;
  align-items: center;
  order: 1;
  position: absolute;
  right: 2%; }
  @media screen and (max-width: 760px) {
    .header--logo-main {
      margin-right: 10px;
      transform: scale(0.9); } }
  .header--logo-main .header--logo-partner {
    color: #75604e;
    text-transform: uppercase;
    max-width: 70px;
    margin-right: 20px; }
    @media screen and (max-width: 760px) {
      .header--logo-main .header--logo-partner {
        margin-top: 0px;
        font-size: 18px;
        margin-right: 25px; } }
  .header--logo-main .logo--wp {
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: column; }
    .header--logo-main .logo--wp p {
      text-transform: uppercase;
      color: #5546ff;
      font-size: 13px;
      border: 2px solid #e5e5e9;
      padding: 5px 10px;
      font-family: 'Jost', sans-serif; }
    @media screen and (max-width: 450px) {
      .header--logo-main .logo--wp img {
        width: 105px; } }

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.wrapper .owl-title {
  color: #869791;
  text-align: center;
  font-family: "Jost", sans-serif;
  font-weight: 450;
  margin-bottom: 15px; }

.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative; }

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  object-fit: contain;
  object-position: center;
  height: 100%; }

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none; }

.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.owl-height {
  transition: height 0.5s ease-in-out; }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease; }

.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none; }

/**
  * Owl Carousel v2.3.4
  * Copyright 2013-2018 David Deutsch
  * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
  */
.owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-nav {
  margin-top: 10px; }

.owl-theme .owl-nav [class*="owl-"] {
  color: #fff;
  font-size: 14px;
  margin: 5px;
  padding: 5px;
  display: inline-block;
  cursor: pointer; }

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1; }

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease; }

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #869791; }

.owl-carousel {
  margin: 5% 0 2%; }
  @media screen and (max-width: 640px) {
    .owl-carousel {
      margin: 3% auto 20px; } }

.owl-item .owl-img {
  position: relative;
  padding-top: calc(108%); }
  @media screen and (max-width: 968px) {
    .owl-item .owl-img {
      padding-top: calc(122%); } }
  @media screen and (max-width: 640px) {
    .owl-item .owl-img {
      padding-top: calc(112%); } }

.owl-item .item img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scale(1);
  transition: all 500ms ease; }

.owl-item.center {
  z-index: 3; }

.owl-dots {
  display: none; }

.owl-theme .owl-nav {
  margin-top: 0px;
  position: absolute;
  width: 27%;
  height: 0%;
  margin: 0px auto;
  left: 0;
  right: 0;
  top: 180px; }
  @media screen and (max-width: 1620px) {
    .owl-theme .owl-nav {
      width: 44%;
      top: 200px; } }
  @media screen and (max-width: 868px) {
    .owl-theme .owl-nav {
      top: 150px; } }
  @media screen and (max-width: 640px) {
    .owl-theme .owl-nav {
      width: 100%;
      top: 200px; } }
  .owl-theme .owl-nav button.owl-prev,
  .owl-theme .owl-nav button.owl-next {
    cursor: pointer;
    transition: all 200ms ease;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 0;
    outline: none;
    width: 58px;
    height: 51px;
    background-image: url(https://xbox-2020.wpcdn.pl/img/owl-arrow.png); }
    .owl-theme .owl-nav button.owl-prev:hover,
    .owl-theme .owl-nav button.owl-next:hover {
      background-image: url(https://xbox-2020.wpcdn.pl/img/owl-arrow-hover.png); }
    .owl-theme .owl-nav button.owl-prev > span,
    .owl-theme .owl-nav button.owl-next > span {
      opacity: 0; }
  .owl-theme .owl-nav button.owl-prev {
    left: 0;
    transform: translate(-50%, -50%) scale(-1); }
  .owl-theme .owl-nav button.owl-next {
    transform: translate(50%, -50%); }

.owl-desc {
  opacity: 0; }

.center .owl-desc {
  opacity: 1;
  margin-left: -15%;
  width: 130%; }
  .center .owl-desc h4 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 12px; }
  .center .owl-desc p {
    width: 100%;
    line-height: 1.6;
    font-size: 14px;
    padding-top: 8px; }

.owl-carousel .owl-nav.disabled {
  display: none !important; }

.owl-carousel.owl-logo.owl-theme {
  margin: 3% auto 50px;
  width: 80%; }
  .owl-carousel.owl-logo.owl-theme:nth-of-type(2) {
    margin-bottom: 0; }
  @media screen and (max-width: 500px) {
    .owl-carousel.owl-logo.owl-theme {
      width: 100%; } }
  .owl-carousel.owl-logo.owl-theme .owl-stage-outer {
    overflow: hidden; }
  .owl-carousel.owl-logo.owl-theme .owl-nav {
    width: 45.5%;
    top: 300px; }
    @media screen and (max-width: 1220px) {
      .owl-carousel.owl-logo.owl-theme .owl-nav {
        width: 45.2%; } }
    @media screen and (max-width: 80px) {
      .owl-carousel.owl-logo.owl-theme .owl-nav {
        width: 45%; } }
    @media screen and (max-width: 880px) {
      .owl-carousel.owl-logo.owl-theme .owl-nav {
        top: 270px;
        width: 47%; } }
    @media screen and (max-width: 640px) {
      .owl-carousel.owl-logo.owl-theme .owl-nav {
        top: 210px;
        width: 63.5%; } }
    @media screen and (max-width: 600px) {
      .owl-carousel.owl-logo.owl-theme .owl-nav {
        width: 64%; } }
    @media screen and (max-width: 520px) {
      .owl-carousel.owl-logo.owl-theme .owl-nav {
        width: 65.5%; } }
    @media screen and (max-width: 480px) {
      .owl-carousel.owl-logo.owl-theme .owl-nav {
        top: 210px;
        width: 86%; } }
    @media screen and (max-width: 380px) {
      .owl-carousel.owl-logo.owl-theme .owl-nav {
        width: 75%; } }
    .owl-carousel.owl-logo.owl-theme .owl-nav button.owl-next {
      right: 0; }
  .owl-carousel.owl-logo.owl-theme .owl-item .owl-img {
    padding-top: 0;
    height: 80px; }
    .owl-carousel.owl-logo.owl-theme .owl-item .owl-img img {
      position: relative;
      height: 80px;
      max-width: 90%;
      margin: 0 auto; }

.hover_bkgr_fricc {
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: none;
  height: 100%;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 10000; }

.hover_bkgr_fricc .helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.hover_bkgr_fricc > div {
  background-color: #fff;
  box-shadow: 10px 10px 60px #555;
  display: inline-block;
  height: auto;
  max-width: 551px;
  min-height: 80px;
  vertical-align: middle;
  width: 180px;
  position: relative;
  border-radius: 8px;
  padding: 15px 5%; }
  .hover_bkgr_fricc > div p {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 90%;
    overflow: hidden;
    width: 85%; }

.popupCloseButton {
  background-color: #fff;
  border: 3px solid #999;
  border-radius: 50px;
  cursor: pointer;
  display: inline-block;
  font-family: arial;
  font-weight: bold;
  position: absolute;
  top: -14px;
  right: -14px;
  font-size: 25px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  text-align: center;
  color: #5243ff; }

p {
  color: black; }

.popupCloseButton:hover {
  background-color: #ccc; }

.trigger_popup_fricc {
  cursor: pointer;
  font-size: 20px;
  margin: 20px;
  display: inline-block;
  font-weight: bold; }

.footer {
  font-size: 1.4rem;
  position: relative;
  width: 100%;
  margin: 0 auto 0;
  background-size: cover;
  color: white;
  background-image: url(https://wpartner-2020.wpcdn.pl/img/footer-bg.jpg); }
  .footer .wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    padding-top: 28px;
    max-width: 1920px;
    flex-direction: column; }
    .footer .wrapper:after {
      display: none; }
    @media screen and (max-width: 1920px) {
      .footer .wrapper {
        padding: 70px 20px;
        padding-bottom: 50px; } }
    @media screen and (max-width: 1024px) {
      .footer .wrapper {
        flex-wrap: wrap; } }
    @media screen and (max-width: 420px) {
      .footer .wrapper {
        flex-direction: column; } }
    .footer .wrapper .footer-title {
      font-size: 48px;
      font-family: 'Jost', sans-serif;
      margin-top: 30px;
      margin-bottom: 40px;
      text-align: center; }
      @media screen and (max-width: 780px) {
        .footer .wrapper .footer-title {
          font-size: 40px; } }
    .footer .wrapper .footer-info {
      display: flex;
      width: 750px;
      justify-content: space-evenly;
      flex-wrap: wrap;
      max-width: 100%; }
      .footer .wrapper .footer-info address {
        font-size: 39px;
        font-style: normal;
        font-family: Jost, sans-serif; }
        @media screen and (max-width: 400px) {
          .footer .wrapper .footer-info address {
            font-size: 29px; } }
      .footer .wrapper .footer-info .person {
        text-align: center;
        margin: 0 8px;
        margin-bottom: 30px; }
        .footer .wrapper .footer-info .person p {
          line-height: 28px;
          font-size: 18px; }
          .footer .wrapper .footer-info .person p:nth-of-type(1) {
            font-weight: 600; }
        .footer .wrapper .footer-info .person img {
          width: 140px;
          height: 140px;
          border-radius: 50%;
          object-fit: cover;
          object-position: center top; }
    .footer .wrapper .footer-content {
      width: 60%;
      margin: 0 auto;
      margin-top: 40px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      line-height: 1.4;
      color: #2a237f; }
      @media screen and (max-width: 1024px) {
        .footer .wrapper .footer-content {
          width: 100%;
          order: 1;
          padding: 10px 0 0;
          margin-bottom: 20px; } }
      .footer .wrapper .footer-content p {
        line-height: 1.4; }
    .footer .wrapper .logo--patron {
      display: flex;
      flex-direction: column; }
      @media screen and (max-width: 420px) {
        .footer .wrapper .logo--patron {
          padding: 14px 0 0; } }
      .footer .wrapper .logo--patron p {
        color: white;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 1px;
        margin-bottom: 10px; }
      .footer .wrapper .logo--patron .logo--desc {
        color: #000;
        font-size: 14px;
        text-transform: uppercase;
        max-width: 70px;
        margin-right: 20px;
        font-weight: 400;
        margin-right: 20px;
        color: #75604e; }
        @media screen and (max-width: 768px) {
          .footer .wrapper .logo--patron .logo--desc {
            font-size: 12px;
            margin-right: 0; } }
      @media screen and (max-width: 768px) {
        .footer .wrapper .logo--patron img {
          width: 100px; } }

.footer--logo {
  margin-right: 80px; }
  @media screen and (max-width: 768px) {
    .footer--logo {
      margin-right: 0; } }

.menu--footer {
  height: 40px;
  line-height: 40px;
  background-color: #fff; }
  @media screen and (max-width: 720px) {
    .menu--footer {
      display: none; } }
  .menu--footer .footer--nav {
    background-color: #5546ff;
    width: 70%;
    position: relative; }
    @media screen and (max-width: 950px) {
      .menu--footer .footer--nav {
        width: calc(100% - 110px); } }
    .menu--footer .footer--nav ul {
      display: flex;
      justify-content: space-evenly; }
      .menu--footer .footer--nav ul li h2 {
        text-transform: uppercase;
        font-size: 16px; }
      .menu--footer .footer--nav ul .social {
        position: absolute;
        height: 100%;
        cursor: pointer; }
        .menu--footer .footer--nav ul .social.fb {
          right: -30px;
          top: 5px; }
          .menu--footer .footer--nav ul .social.fb:before {
            content: "";
            display: block;
            position: absolute;
            width: 11px;
            height: 20px;
            left: 50%;
            top: 5px;
            transform: translate(-50%, 0%); }
          .menu--footer .footer--nav ul .social.fb:hover:before {
            background-image: url(https://opel-2020.wpcdn.pl/img/fb-hover.png); }
          .menu--footer .footer--nav ul .social.fb:hover img {
            opacity: 0; }
        .menu--footer .footer--nav ul .social.tw {
          right: -80px;
          top: 5px; }
          .menu--footer .footer--nav ul .social.tw:before {
            content: "";
            display: block;
            position: absolute;
            width: 28px;
            height: 21px;
            left: 50%;
            top: 4px;
            transform: translate(-50%, 0%); }
          .menu--footer .footer--nav ul .social.tw:hover:before {
            background-image: url(https://opel-2020.wpcdn.pl/img/yt-hover.png); }
          .menu--footer .footer--nav ul .social.tw:hover img {
            opacity: 0; }

.footer-links {
  padding-top: 2vw;
  text-align: center; }
  .footer-links a {
    color: #fff;
    display: block;
    margin: 0 auto;
    padding: .6vw 0; }

.opening-text img {
  width: 545px;
  max-width: 90%; }

.section-wrapper.icons .wrapper .container {
  margin-top: 60px; }
  .section-wrapper.icons .wrapper .container .element {
    display: flex;
    width: 70%;
    margin: 0 auto;
    justify-content: space-around;
    margin-bottom: 50px;
    overflow: hidden; }
    @media screen and (max-width: 980px) {
      .section-wrapper.icons .wrapper .container .element {
        width: 80%; } }
    @media screen and (max-width: 785px) {
      .section-wrapper.icons .wrapper .container .element {
        width: 90%; } }
    @media screen and (max-width: 705px) {
      .section-wrapper.icons .wrapper .container .element {
        width: 100%; } }
    @media screen and (max-width: 505px) {
      .section-wrapper.icons .wrapper .container .element {
        flex-wrap: wrap; } }
    .section-wrapper.icons .wrapper .container .element:nth-last-child(1) {
      margin-bottom: 0; }
    .section-wrapper.icons .wrapper .container .element:nth-of-type(2n) .img {
      text-align: left;
      transition: .5s;
      order: -1; }
      @media screen and (max-width: 505px) {
        .section-wrapper.icons .wrapper .container .element:nth-of-type(2n) .img {
          text-align: center;
          order: 1; } }
    .section-wrapper.icons .wrapper .container .element .text {
      width: 48%;
      color: #343440;
      position: relative; }
      @media screen and (max-width: 505px) {
        .section-wrapper.icons .wrapper .container .element .text {
          width: 100%; } }
      .section-wrapper.icons .wrapper .container .element .text .content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
        @media screen and (max-width: 505px) {
          .section-wrapper.icons .wrapper .container .element .text .content {
            position: static;
            transform: unset; } }
        .section-wrapper.icons .wrapper .container .element .text .content h3 {
          font-weight: 500;
          font-size: 30px;
          display: inline-grid; }
          @media screen and (max-width: 860px) {
            .section-wrapper.icons .wrapper .container .element .text .content h3 {
              font-size: 26px; } }
          @media screen and (max-width: 640px) {
            .section-wrapper.icons .wrapper .container .element .text .content h3 {
              font-size: 21px; } }
          .section-wrapper.icons .wrapper .container .element .text .content h3 span {
            display: inline-block;
            font-weight: 700;
            position: relative;
            font-family: "Jost", sans-serif;
            border-bottom: 6px solid #5546ff;
            line-height: 28px; }
        .section-wrapper.icons .wrapper .container .element .text .content p {
          margin-top: 20px;
          color: #848497; }
    .section-wrapper.icons .wrapper .container .element .img {
      width: 380px;
      height: 380px;
      text-align: right;
      transform: translateY(-120%);
      transition: .7s;
      margin: -40px 0;
      opacity: 0; }
      @media screen and (max-width: 1030px) {
        .section-wrapper.icons .wrapper .container .element .img {
          width: 350px;
          height: 350px; } }
      @media screen and (max-width: 560px) {
        .section-wrapper.icons .wrapper .container .element .img {
          width: 50%; } }
      @media screen and (max-width: 505px) {
        .section-wrapper.icons .wrapper .container .element .img {
          width: 100%;
          text-align: center;
          margin: -50px; } }
      @media screen and (max-width: 360px) {
        .section-wrapper.icons .wrapper .container .element .img {
          margin: -60px; } }
      .section-wrapper.icons .wrapper .container .element .img img {
        max-width: 100%; }
    .section-wrapper.icons .wrapper .container .element.__js_inView .img {
      transform: translate(0);
      opacity: 1; }

.icon1 .st0 {
  fill: #CCC8FF;
  animation: wave 5s ease-in-out infinite; }

.icon1 .st1 {
  fill: #FAFAFC; }

.icon1 .st2 {
  fill: #E9E9F3; }

.icon1 .st3 {
  fill: #5546FF; }

.icon1 .st4 {
  fill: #7E7E92; }

.icon1 .st5 {
  fill: #E5E5E9; }

.icon1 .st6 {
  fill: #EEECFF; }

.icon1 .st7 {
  fill: #FFFFFF; }

.icon1 path.diagram {
  animation: rotate 8s 2 linear;
  transform-origin: 643px 383px; }

.icon1 .circle {
  transition: .5s;
  animation: opacity 3s infinite ease-in-out; }

.icon1 .circle-blue {
  transform-origin: 450px 110px;
  animation: circle-blue infinite 6s ease-in-out; }

.icon2 .st0 {
  fill: #E9E9F3; }

.icon2 .st1 {
  fill: #E5E5E9; }

.icon2 .st2 {
  fill: #FAFAFC; }

.icon2 .st3 {
  fill: #5546FF; }

.icon2 .st4 {
  fill: #7E7E92; }

.icon2 .st5 {
  fill: #FFFFFF; }

.icon2 .bar-1 {
  animation: barBefore 6s linear infinite;
  fill: #5546ff; }

.icon2 .bar-2 {
  animation: bar 6s linear infinite;
  fill: #5546ff; }

.icon2 .bar-3 {
  animation: barAfter 6s linear infinite;
  fill: #5546ff; }

.icon2 .triangle-blue {
  transform-origin: 139px 470px;
  animation: triangle-blue 10s infinite alternate linear; }

.icon3 .st0 {
  fill: #FAFAFC; }

.icon3 .st1 {
  fill: #E9E9F3; }

.icon3 .st2 {
  fill: #EEECFF; }

.icon3 .st3 {
  fill: #5546FF; }

.icon3 .st4 {
  fill: #E5E5E9; }

.icon3 .st5 {
  fill: #7E7E92; }

.icon3 .st6 {
  fill: #FFFFFF; }

.icon3 .st7 {
  fill: #B7B7CC; }

.icon3 .arrow {
  animation: arrow 5s infinite ease-in-out; }

.icon3 .line {
  animation: arrow-line 5s infinite ease-in-out; }

.icon3 .bar {
  animation: arrow-bar 5s infinite ease-in-out; }

.icon3 .st7.bar {
  animation: arrow-bar-smaller 5s infinite ease-in-out; }

.icon3 .small-circle {
  transform-origin: 400px 400px;
  animation: rotate-small 15s linear infinite; }

.icon3 .small-triangle {
  transform-origin: 400px 400px;
  animation: rotate-small 20s linear reverse infinite; }

@keyframes rotate {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes opacity {
  0% {
    opacity: 0; }
  25% {
    opacity: 1; }
  75% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes transparent {
  0% {
    fill: transparent; }
  25% {
    fill: #fff; }
  75% {
    fill: #fff; }
  100% {
    fill: transparent; } }

@keyframes dots {
  0% {
    fill: #fff; }
  25% {
    fill: #fff; }
  50% {
    fill: #5546ff; }
  75% {
    fill: #5546ff; }
  100% {
    fill: #fff; } }

@keyframes arrow {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(-100px); }
  100% {
    transform: translateY(0); } }

@keyframes arrow-line {
  0% {
    transform: scaleY(1); }
  50% {
    transform: translateY(-325px) scaleY(1.5); }
  100% {
    transform: translateY(0) scaleY(1); } }

@keyframes arrow-bar {
  0% {
    transform: scaleY(1); }
  50% {
    transform: translateY(-325px) scaleY(1.5); }
  100% {
    transform: translateY(0) scaleY(1); } }

@keyframes arrow-bar-smaller {
  0% {
    transform: scaleY(1); }
  50% {
    transform: translateY(-193.8px) scaleY(1.3); }
  100% {
    transform: translateY(0) scaleY(1); } }

@keyframes wave {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(20px); }
  100% {
    transform: translateX(0); } }

@keyframes barBefore {
  0% {
    fill: #7e7e92;
    height: 57px;
    transform: translateY(0); }
  25% {
    fill: #5546ff;
    height: 87px;
    transform: translateY(-30px); }
  50% {
    fill: #5546ff;
    height: 87px;
    transform: translateY(-30px); }
  75% {
    fill: #5546ff;
    height: 87px;
    transform: translateY(-30px); }
  100% {
    fill: #7e7e92;
    height: 57px;
    transform: translateY(0); } }

@keyframes bar {
  0% {
    fill: #7e7e92; }
  25% {
    fill: #7e7e92;
    height: 104.8px;
    transform: translateY(0); }
  50% {
    fill: #5546ff;
    height: 134.8px;
    transform: translateY(-30px); }
  75% {
    fill: #5546ff;
    height: 134.8px;
    transform: translateY(-30px); }
  100% {
    fill: #7e7e92;
    height: 104.8px;
    transform: translateY(0); } }

@keyframes barAfter {
  0% {
    fill: #7e7e92; }
  25% {
    fill: #7e7e92; }
  50% {
    fill: #7e7e92;
    height: 208px;
    transform: translateY(0); }
  75% {
    fill: #5546ff;
    height: 228px;
    transform: translateY(-20px); }
  100% {
    fill: #7e7e92;
    height: 208px;
    transform: translateY(0); } }

@keyframes rotate-small {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes circle-blue {
  0% {
    transform: translate(0); }
  50% {
    transform: translate(100px, 80px); }
  100% {
    transform: translate(0); } }

@keyframes triangle-blue {
  0% {
    transform: translate(0); }
  30% {
    transform: translate(0, 112px); }
  100% {
    transform: translate(340px, 112px); } }

.section-wrapper.graphs .wrapper .container {
  display: flex;
  width: 80%;
  margin: 0 auto;
  margin-top: 60px;
  justify-content: space-evenly; }
  @media screen and (max-width: 730px) {
    .section-wrapper.graphs .wrapper .container {
      flex-wrap: wrap;
      margin-top: 30px;
      width: 90%; } }
  .section-wrapper.graphs .wrapper .container .element {
    margin: 0 25px; }
    .section-wrapper.graphs .wrapper .container .element:nth-of-type(2) {
      margin-right: 0; }
    @media screen and (max-width: 730px) {
      .section-wrapper.graphs .wrapper .container .element {
        width: 100%;
        text-align: center; }
        .section-wrapper.graphs .wrapper .container .element:nth-of-type(2) {
          margin: 0 25px; } }
    .section-wrapper.graphs .wrapper .container .element h3 {
      font-family: "Jost", sans-serif;
      font-size: 24px;
      color: #343440;
      margin-bottom: 30px;
      text-align: left; }
    .section-wrapper.graphs .wrapper .container .element:nth-of-type(2) h3 {
      color: #5d4ffd; }
      @media screen and (max-width: 730px) {
        .section-wrapper.graphs .wrapper .container .element:nth-of-type(2) h3 {
          margin-top: 20px; } }
    .section-wrapper.graphs .wrapper .container .element ul {
      text-align: left; }
      .section-wrapper.graphs .wrapper .container .element ul li {
        position: relative;
        color: #3d3d51;
        margin-bottom: 25px;
        font-size: 15px; }
        .section-wrapper.graphs .wrapper .container .element ul li:before {
          content: "";
          display: block;
          position: absolute;
          left: -17px;
          top: 3px;
          width: 10px;
          height: 12px;
          background-image: url(https://wpartner-2020.wpcdn.pl/img/list.png);
          background-size: cover; }
    .section-wrapper.graphs .wrapper .container .element img {
      max-width: 100%;
      transition: .5s;
      transform: translateY(-50%);
      opacity: 0;
      margin: 40px 0; }
    .section-wrapper.graphs .wrapper .container .element.__js_inView img {
      transform: translateY(0);
      opacity: 1; }

.section-wrapper.partner {
  background-color: white;
  padding-top: 20px; }
  .section-wrapper.partner .wrapper {
    text-align: center;
    padding-top: 0; }
    .section-wrapper.partner .wrapper img {
      margin: 30px 0; }
  .section-wrapper.partner .main-text {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 20px; }
    @media screen and (max-width: 740px) {
      .section-wrapper.partner .main-text {
        flex-direction: column;
        align-items: center; } }
    .section-wrapper.partner .main-text img {
      margin-left: 10px;
      transform: translateY(20%); }
  .section-wrapper.partner p {
    color: #869791; }

.section-wrapper.signs {
  background-color: #5546ff !important; }
  .section-wrapper.signs .wrapper h2 {
    width: 88%;
    text-align: left;
    margin: 0 auto;
    margin-bottom: 60px;
    font-family: 'Jost';
    font-size: 26px; }
  .section-wrapper.signs .wrapper .container {
    display: flex;
    width: 85%;
    margin: 0 auto; }
    @media screen and (max-width: 880px) {
      .section-wrapper.signs .wrapper .container {
        flex-wrap: wrap; } }
    @media screen and (max-width: 730px) {
      .section-wrapper.signs .wrapper .container {
        width: 90%; } }
    @media screen and (max-width: 425px) {
      .section-wrapper.signs .wrapper .container {
        width: 100%; } }
    .section-wrapper.signs .wrapper .container .text {
      width: 40%;
      margin-right: 50px; }
      .section-wrapper.signs .wrapper .container .text li {
        position: relative;
        margin-bottom: 1em; }
        .section-wrapper.signs .wrapper .container .text li:before {
          content: "";
          display: block;
          position: absolute;
          left: -17px;
          top: 3px;
          width: 10px;
          height: 12px;
          background-image: url(https://wpartner-2020.wpcdn.pl/img/list_demand.png);
          background-size: contain;
          background-repeat: no-repeat; }
      @media screen and (max-width: 1025px) {
        .section-wrapper.signs .wrapper .container .text {
          width: 30%; } }
      @media screen and (max-width: 880px) {
        .section-wrapper.signs .wrapper .container .text {
          width: 100%;
          margin-right: 0;
          margin-left: 20px;
          padding-bottom: 20px; } }
      .section-wrapper.signs .wrapper .container .text h2 {
        font-family: "Jost", sans-serif;
        margin-bottom: 20px;
        font-size: 28px;
        color: #fff; }
      .section-wrapper.signs .wrapper .container .text p {
        margin-bottom: 20px;
        color: #fff; }
    .section-wrapper.signs .wrapper .container .signs {
      width: 68%; }
      @media screen and (max-width: 1025px) {
        .section-wrapper.signs .wrapper .container .signs {
          width: 70%; } }
      @media screen and (max-width: 880px) {
        .section-wrapper.signs .wrapper .container .signs {
          width: 100%; } }
      .section-wrapper.signs .wrapper .container .signs .icons {
        display: flex;
        justify-content: flex-start; }
        @media screen and (max-width: 880px) {
          .section-wrapper.signs .wrapper .container .signs .icons {
            margin-top: 0;
            justify-content: center; } }
        @media screen and (max-width: 425px) {
          .section-wrapper.signs .wrapper .container .signs .icons {
            flex-wrap: wrap; } }
        .section-wrapper.signs .wrapper .container .signs .icons .icon {
          text-align: center;
          margin: 0 10px;
          width: 115px; }
          @media screen and (max-width: 425px) {
            .section-wrapper.signs .wrapper .container .signs .icons .icon {
              width: calc(50% - 20px); } }
          .section-wrapper.signs .wrapper .container .signs .icons .icon .img {
            position: relative;
            height: 60px; }
            .section-wrapper.signs .wrapper .container .signs .icons .icon .img:before {
              content: "";
              display: block;
              position: absolute;
              width: 40px;
              height: 100%;
              background-color: #3b31b2;
              left: 50%;
              top: 0;
              transform: translateX(-50%);
              clip-path: polygon(50% 0%, 100% 0, 50% 100%, 0 100%);
              animation: bg-icons 12s infinite ease-in-out; }
            .section-wrapper.signs .wrapper .container .signs .icons .icon .img img {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              max-width: 38px;
              animation: icons 6s infinite linear; }
          .section-wrapper.signs .wrapper .container .signs .icons .icon p {
            color: #fff;
            text-align: center;
            margin-top: 15px; }
      .section-wrapper.signs .wrapper .container .signs .desc {
        display: flex;
        color: #333247;
        margin-top: 30px; }
        @media screen and (max-width: 450px) {
          .section-wrapper.signs .wrapper .container .signs .desc {
            flex-wrap: wrap; } }
        .section-wrapper.signs .wrapper .container .signs .desc .label {
          margin-top: 6px;
          color: #fff; }
        .section-wrapper.signs .wrapper .container .signs .desc .desc-shortcuts {
          margin-left: 20px;
          color: #fff; }
          @media screen and (max-width: 450px) {
            .section-wrapper.signs .wrapper .container .signs .desc .desc-shortcuts {
              margin-left: 0;
              margin-top: 10px; } }
          .section-wrapper.signs .wrapper .container .signs .desc .desc-shortcuts .shortcuts {
            display: flex;
            margin-bottom: 20px;
            align-items: center; }
            .section-wrapper.signs .wrapper .container .signs .desc .desc-shortcuts .shortcuts p {
              background-color: #3b31b2;
              border-radius: 50%;
              margin-right: 30px;
              height: 35px;
              width: 35px;
              text-align: center;
              line-height: 38px;
              color: #fff;
              animation: text 8s infinite ease-in-out; }
              @media screen and (max-width: 450px) {
                .section-wrapper.signs .wrapper .container .signs .desc .desc-shortcuts .shortcuts p {
                  margin-right: 20px; } }
          .section-wrapper.signs .wrapper .container .signs .desc .desc-shortcuts p {
            color: #c4bfff; }

@keyframes icons {
  0% {
    transform: translate(-50%, -50%); }
  25% {
    transform: translate(-50%, -50%) scale(1.15); }
  50% {
    transform: translate(-50%, -50%); }
  75% {
    transform: translate(-50%, -50%) scale(1.15); }
  100% {
    transform: translate(-50%, -50%); } }

@keyframes text {
  0% {
    transform: scale(1); }
  25% {
    transform: scale(0.9); }
  50% {
    transform: scale(1); }
  75% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@keyframes bg-icons {
  0% {
    transform: translateX(-50%) scaleX(1); }
  25% {
    transform: translateX(-50%) scaleX(-1); }
  50% {
    transform: translateX(-50%) scaleX(1); }
  75% {
    transform: translateX(-50%) scaleX(-1); }
  100% {
    transform: translateX(-50%) scaleX(1); } }

.contact {
  background-color: #efeef6 !important; }
  .contact .wrapper .container {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: space-evenly; }
    @media screen and (max-width: 760px) {
      .contact .wrapper .container {
        justify-content: flex-start; } }
    .contact .wrapper .container p {
      color: #737386; }
    .contact .wrapper .container .form {
      width: 50%; }
      @media screen and (max-width: 800px) {
        .contact .wrapper .container .form {
          width: 60%; } }
      @media screen and (max-width: 760px) {
        .contact .wrapper .container .form {
          width: 100%; } }
      @media screen and (max-width: 600px) {
        .contact .wrapper .container .form {
          width: 100%; } }
      .contact .wrapper .container .form h2 {
        color: #353542;
        font-family: "Jost", sans-serif;
        margin-bottom: 30px;
        font-size: 26px; }
      .contact .wrapper .container .form form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        padding-bottom: 30px; }
        .contact .wrapper .container .form form .error {
          font-size: 10px;
          font-weight: 700;
          color: #a94442;
          margin-left: 85px;
          display: none;
          width: 100%;
          position: absolute;
          bottom: 0;
          transform: translateY(100%); }
          @media screen and (max-width: 600px) {
            .contact .wrapper .container .form form .error.error-checkbox {
              margin-left: 35px; } }
        .contact .wrapper .container .form form .blank {
          width: 100%;
          margin-bottom: 20px;
          color: #333247;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          position: relative; }
          .contact .wrapper .container .form form .blank p {
            font-size: 15px;
            width: 85px;
            display: inline-block; }
          .contact .wrapper .container .form form .blank.desc {
            width: 100%;
            align-items: flex-start; }
          .contact .wrapper .container .form form .blank input {
            width: 200px;
            max-width: calc(100% - 94px);
            border: none;
            height: 28px;
            outline: none;
            margin-top: 5px;
            border-radius: 1px;
            padding-left: 5px;
            border: 1px solid #cdcae1;
            transition: .5s;
            font-family: 'Varta', sans-serif;
            font-size: 18px;
            color: #333247; }
            .contact .wrapper .container .form form .blank input:focus {
              border: 1px solid #5243ff; }
          .contact .wrapper .container .form form .blank input::-webkit-outer-spin-button,
          .contact .wrapper .container .form form .blank input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0; }
          .contact .wrapper .container .form form .blank input[type=number] {
            -moz-appearance: textfield; }
          .contact .wrapper .container .form form .blank textarea {
            width: auto;
            height: 120px;
            flex-grow: 1;
            resize: none;
            outline: none;
            border: none;
            margin-top: 5px;
            font-family: "Varta", sans-serif;
            border-radius: 1px;
            padding-left: 5px;
            border: 1px solid #cdcae1;
            transition: .5s; }
            .contact .wrapper .container .form form .blank textarea:focus {
              border: 1px solid #5243ff; }
        .contact .wrapper .container .form form .submit {
          background-color: #5243ff;
          color: #fff;
          border: 0;
          outline: 0;
          padding: 10px 30px;
          padding-bottom: 8px;
          font-weight: bold;
          cursor: pointer;
          transition: .5s;
          font-family: 'Varta', sans-serif;
          font-size: 16px;
          margin-left: 85px;
          margin-top: 30px; }
          @media screen and (max-width: 600px) {
            .contact .wrapper .container .form form .submit {
              margin-left: 0; } }
          .contact .wrapper .container .form form .submit:hover {
            transform: scale(1.05); }
    .contact .wrapper .container .contact-details {
      margin-left: 60px; }
      @media screen and (max-width: 800px) {
        .contact .wrapper .container .contact-details {
          margin-left: 0;
          order: -1;
          margin-bottom: 20px;
          width: 60%; } }
      .contact .wrapper .container .contact-details h2 {
        font-size: 20px;
        color: #6e6e80;
        font-family: 'Jost', sans-serif;
        font-weight: normal;
        margin-bottom: 50px; }
      .contact .wrapper .container .contact-details p {
        color: #3d3d51;
        margin-bottom: 25px;
        position: relative;
        margin-left: 18px;
        font-size: 17px; }
        .contact .wrapper .container .contact-details p:before {
          content: "";
          display: block;
          position: absolute;
          left: -17px;
          top: 3px;
          width: 10px;
          height: 12px;
          background-image: url(https://wpartner-2020.wpcdn.pl/img/list.png);
          background-size: cover; }
        .contact .wrapper .container .contact-details p span {
          font-weight: bold; }
          .contact .wrapper .container .contact-details p span.blue {
            color: #5546ff; }

.rodo--container {
  position: relative;
  margin-top: 6px; }

.rodo--label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #000;
  font-weight: 300;
  font-size: 12px;
  margin-left: 85px; }
  @media screen and (max-width: 600px) {
    .rodo--label {
      margin-left: 0; } }
  .rodo--label:nth-of-type(3) {
    margin-bottom: 5px; }
  .rodo--label:hover .rodo--label__checkmark {
    background-color: #ddd; }
  .rodo--label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .rodo--label input:checked + .rodo--label__checkmark {
      background-color: #5243ff; }
      .rodo--label input:checked + .rodo--label__checkmark::after {
        display: block; }
  .rodo--label .rodo--label__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 1px solid black;
    transition: .5s; }
    .rodo--label .rodo--label__checkmark::after {
      content: "";
      position: absolute;
      display: none;
      left: 8px;
      top: 4px;
      width: 7px;
      height: 11px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .rodo--label .main-form__label--error {
    color: #a94442;
    font-weight: 700; }

.g-recaptcha {
  width: 100%;
  margin-left: 85px;
  margin-top: 15px; }
  @media screen and (max-width: 600px) {
    .g-recaptcha {
      margin-left: 0; } }

.rodo--info {
  margin-left: 85px;
  font-size: 12px;
  color: #000 !important; }
  @media screen and (max-width: 600px) {
    .rodo--info {
      margin-left: 0; } }
  .rodo--info span {
    text-decoration: underline;
    cursor: pointer; }

.rodo--info--full {
  margin-left: 85px;
  font-size: 12px;
  color: #000 !important; }
  @media screen and (max-width: 600px) {
    .rodo--info--full {
      margin-left: 0; } }
  .rodo--info--full span {
    text-decoration: underline;
    cursor: pointer; }
  .rodo--info--full a {
    color: #ec1c24; }
  .rodo--info--full.hidden {
    display: none; }

.map .wrapper {
  padding: 0; }
  @media screen and (max-width: 1280px) {
    .map .wrapper {
      padding: 0 20px; } }
  .map .wrapper .container {
    display: flex;
    height: 300px; }
    @media screen and (max-width: 600px) {
      .map .wrapper .container {
        flex-wrap: wrap;
        height: auto; } }
    .map .wrapper .container .img {
      width: 35%; }
      @media screen and (max-width: 600px) {
        .map .wrapper .container .img {
          width: 100%;
          height: 250px; } }
      .map .wrapper .container .img img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .map .wrapper .container .mapouter {
      width: 65%;
      height: 100%; }
      @media screen and (max-width: 600px) {
        .map .wrapper .container .mapouter {
          width: 100%;
          height: 300px; } }
      .map .wrapper .container .mapouter .gmap_canvas {
        width: 100%;
        height: 100%; }
        .map .wrapper .container .mapouter .gmap_canvas #gmap_canvas {
          width: 100%;
          height: 100%; }

.g-recaptcha > div {
  left: 50%;
  position: relative;
  transform: translateX(-50%); }

.about-tech .wrapper .container {
  display: flex;
  width: 80%;
  margin: 0 auto;
  justify-content: space-evenly;
  flex-wrap: wrap;
  overflow: hidden; }
  @media screen and (max-width: 1020px) {
    .about-tech .wrapper .container {
      width: 90%; } }
  @media screen and (max-width: 850px) {
    .about-tech .wrapper .container {
      width: 100%; } }
  .about-tech .wrapper .container .element {
    width: 28%;
    text-align: center;
    opacity: 0;
    transition: 1s; }
    @media screen and (max-width: 780px) {
      .about-tech .wrapper .container .element {
        width: 46%;
        margin-bottom: 20px; } }
    @media screen and (max-width: 450px) {
      .about-tech .wrapper .container .element {
        width: 75%; } }
    .about-tech .wrapper .container .element img {
      height: 150px;
      max-width: 100%; }
    .about-tech .wrapper .container .element p {
      color: #343440;
      text-align: center; }
      .about-tech .wrapper .container .element p span {
        font-weight: bold; }
    .about-tech .wrapper .container .element.__js_inView {
      opacity: 1; }

.tech-1 .st0 {
  fill: #E9E9F3; }

.tech-1 .st1 {
  fill: #5546FF; }

.tech-1 .st2 {
  fill: #FFFFFF; }

.tech-1 .st3 {
  fill: #3988C3; }

.tech-1 .st4 {
  fill: #FF6F00; }

.tech-1 .letter-1 {
  animation: letter-1 3 6s; }

.tech-1 .letter-2 {
  animation: letter-2 3 6s; }

.tech-1 .letter-3 {
  animation: letter-3 3 6s; }

.tech-1 .letter-4 {
  animation: letter-4 3 6s; }

.tech-1 .letter-5 {
  animation: letter-5 3 6s; }

.tech-1 .letter-6 {
  animation: letter-6 3 6s; }

.tech-1 .circle-grey {
  transform-origin: 600px 367px;
  animation: circle-xy infinite 12s ease-in-out; }

.tech-1 .circle-blue {
  transform-origin: 100px 500px;
  animation: circle-y 10s infinite ease-in-out; }

.tech-2 .st0 {
  fill: #E9E9F3; }

.tech-2 .st1 {
  fill: #5546FF; }

.tech-2 .st2 {
  fill: #FFFFFF; }

.tech-2 .st3 {
  fill: none;
  stroke: #E9E9F3;
  stroke-width: 8;
  stroke-miterlimit: 10; }

.tech-2 .arrow {
  animation: arrow-2 infinite 5s linear; }

.tech-2 .circle-blue {
  transform-origin: 100px 500px;
  animation: circle-x 10s infinite ease-in-out; }

.tech-3 {
  transform: translateY(17px); }
  .tech-3 .st0 {
    fill: #E9E9F3; }
  .tech-3 .st1 {
    fill: #5546FF; }
  .tech-3 .st2 {
    fill: #FFFFFF; }
  .tech-3 .bar {
    transform: translateY(-50px); }
  .tech-3 .arrow {
    transform-origin: 400px 450px;
    animation: arrow-3 5s infinite; }
  .tech-3 .circle-grey {
    transform-origin: 600px 367px;
    animation: circle-rotate 20s infinite; }
  .tech-3 .circle-blue {
    transform-origin: 100px 500px;
    animation: circle-y 12s infinite ease-in-out; }

@keyframes circle-x {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(680px); }
  100% {
    transform: translateX(0); } }

@keyframes circle-y {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(-300px); }
  100% {
    transform: translateY(0); } }

@keyframes circle-xy {
  0% {
    transform: translate(0, 0); }
  50% {
    transform: translate(-430px, 530px); }
  100% {
    transform: translate(0, 0); } }

@keyframes circle-rotate {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes arrow-2 {
  0% {
    transform: translateY(0); }
  25% {
    transform: translateY(15px); }
  50% {
    transform: translateY(0); }
  75% {
    transform: translateY(-15px); }
  100% {
    transform: translateY(0); } }

@keyframes arrow-3 {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.15); }
  100% {
    transform: scale(1); } }

@keyframes letter-1 {
  0% {
    opacity: 0; }
  16.66% {
    opacity: 1; }
  33.33% {
    opacity: 1; }
  49.99% {
    opacity: 1; }
  66.65% {
    opacity: 1; }
  83.31% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes letter-2 {
  0% {
    opacity: 0; }
  16.66% {
    opacity: 0; }
  33.33% {
    opacity: 1; }
  49.99% {
    opacity: 1; }
  66.65% {
    opacity: 1; }
  83.31% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes letter-3 {
  0% {
    opacity: 0; }
  16.66% {
    opacity: 0; }
  33.33% {
    opacity: 0; }
  49.99% {
    opacity: 1; }
  66.65% {
    opacity: 1; }
  83.31% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes letter-4 {
  0% {
    opacity: 0; }
  16.66% {
    opacity: 0; }
  33.33% {
    opacity: 0; }
  49.99% {
    opacity: 0; }
  66.65% {
    opacity: 1; }
  83.31% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes letter-5 {
  0% {
    opacity: 0; }
  16.66% {
    opacity: 0; }
  33.33% {
    opacity: 0; }
  49.99% {
    opacity: 0; }
  66.65% {
    opacity: 0; }
  83.31% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes letter-6 {
  0% {
    opacity: 0; }
  16.66% {
    opacity: 0; }
  33.33% {
    opacity: 0; }
  49.99% {
    opacity: 0; }
  66.65% {
    opacity: 0; }
  83.31% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.benefits {
  background-color: #5546ff !important; }
  .benefits .wrapper {
    display: flex;
    justify-content: center; }
    @media screen and (max-width: 700px) {
      .benefits .wrapper {
        display: block; } }
    .benefits .wrapper .title {
      color: #fff;
      font-family: "Jost", sans-serif;
      font-weight: bold; }
    .benefits .wrapper .container {
      width: 67%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: 40px;
      margin-left: 35px; }
      @media screen and (max-width: 920px) {
        .benefits .wrapper .container {
          width: 83%; } }
      @media screen and (max-width: 700px) {
        .benefits .wrapper .container {
          width: 100%;
          margin-top: 25px;
          margin-left: 0; } }
      .benefits .wrapper .container .element {
        width: 45%;
        display: flex;
        margin-bottom: 35px;
        align-items: center; }
        @media screen and (max-width: 820px) {
          .benefits .wrapper .container .element {
            width: 49%; } }
        @media screen and (max-width: 480px) {
          .benefits .wrapper .container .element {
            width: 100%;
            justify-content: center; } }
        .benefits .wrapper .container .element:nth-of-type(odd) {
          margin-right: 10px; }
          @media screen and (max-width: 700px) {
            .benefits .wrapper .container .element:nth-of-type(odd) {
              margin-right: 0; } }
        .benefits .wrapper .container .element .img {
          position: relative;
          width: 85px;
          height: 85px; }
          .benefits .wrapper .container .element .img:before {
            content: "";
            display: block;
            position: absolute;
            width: 50px;
            height: 100%;
            background-color: #3b31b2;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            clip-path: polygon(50% 0%, 100% 0, 50% 100%, 0 100%);
            animation: bg-icons 12s infinite ease-in-out; }
          .benefits .wrapper .container .element .img img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-width: 42px;
            animation: icons 6s infinite linear; }
        .benefits .wrapper .container .element p {
          margin-left: 5px;
          width: 180px; }

@keyframes icons {
  0% {
    transform: translate(-50%, -50%); }
  25% {
    transform: translate(-50%, -50%) scale(1.15); }
  50% {
    transform: translate(-50%, -50%); }
  75% {
    transform: translate(-50%, -50%) scale(1.15); }
  100% {
    transform: translate(-50%, -50%); } }

@keyframes bg-icons {
  0% {
    transform: translateX(-50%) scaleX(1); }
  25% {
    transform: translateX(-50%) scaleX(-1); }
  50% {
    transform: translateX(-50%) scaleX(1); }
  75% {
    transform: translateX(-50%) scaleX(-1); }
  100% {
    transform: translateX(-50%) scaleX(1); } }

.target {
  background-color: #efeef6 !important; }
  .target .wrapper {
    padding: 40px 20px;
    padding-bottom: 100px; }
    .target .wrapper .title {
      font-family: "Jost", sans-serif;
      font-weight: bold; }
    .target .wrapper .container {
      display: flex;
      justify-content: center;
      margin-top: 10px; }
      .target .wrapper .container .img {
        width: 379px;
        height: 280px;
        position: relative; }
        @media screen and (max-width: 850px) {
          .target .wrapper .container .img {
            width: 200px;
            height: 170px; } }
        .target .wrapper .container .img img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .target .wrapper .container p {
        position: absolute;
        width: 200px;
        color: #000000;
        font-size: 15px; }
        .target .wrapper .container p span {
          color: #5546ff; }
        .target .wrapper .container p:nth-of-type(1) {
          left: -10px;
          top: 50%;
          transform: translate(-100%, -70%); }
        .target .wrapper .container p:nth-of-type(2) {
          right: -10px;
          top: 50%;
          transform: translate(100%, -70%); }
        .target .wrapper .container p:nth-of-type(3) {
          right: 50%;
          transform: translate(50%, 0%);
          text-align: center; }

.list {
  background-color: #fff !important; }
  .list .wrapper {
    display: flex;
    justify-content: center; }
    @media screen and (max-width: 700px) {
      .list .wrapper {
        display: block; } }
    .list .wrapper .title {
      color: #3b3b3a;
      font-family: "Jost", sans-serif;
      font-weight: bold; }
    .list .wrapper ul {
      display: flex;
      width: 70%;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: 50px;
      margin-left: 40px; }
      @media screen and (max-width: 700px) {
        .list .wrapper ul {
          width: 100%;
          margin-left: 10px;
          margin-top: 25px; } }
      .list .wrapper ul li {
        width: calc(33% - 33px);
        color: #3b3b3a;
        position: relative;
        margin-bottom: 20px;
        padding-left: 24px;
        margin-right: 10px; }
        @media screen and (max-width: 510px) {
          .list .wrapper ul li {
            width: 46%; } }
        @media screen and (max-width: 400px) {
          .list .wrapper ul li {
            width: 100%; } }
        .list .wrapper ul li span {
          font-weight: bold; }
        .list .wrapper ul li:before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 5px;
          width: 19px;
          height: 10px;
          background-image: url(https://wpartner-2020.wpcdn.pl/img/list-img.png);
          background-size: cover; }

.why-this-ad {
  color: #63636C;
  min-height: calc(100vh - 90px - 202px); }
  @media screen and (max-width: 1920px) {
    .why-this-ad {
      min-height: calc(100vh - 90px - 2vw - 206px); } }
  .why-this-ad h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.44;
    color: #3B3B3A;
    font-family: "Jost", sans-serif;
    padding-bottom: 20px; }
  .why-this-ad h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.44;
    color: #3B3B3A;
    font-family: "Jost", sans-serif;
    padding-bottom: 20px; }
  .why-this-ad p {
    padding-bottom: 10px; }
  .why-this-ad a {
    color: #5546FF; }
  .why-this-ad .wrapper {
    max-width: 930px; }
  .why-this-ad .container {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: space-evenly; }
    @media screen and (max-width: 760px) {
      .why-this-ad .container {
        justify-content: flex-start; } }
  .why-this-ad .col {
    width: 60%;
    order: 0; }
    @media screen and (max-width: 760px) {
      .why-this-ad .col {
        width: 100%; } }
    @media screen and (max-width: 600px) {
      .why-this-ad .col {
        width: 100%; } }
    .why-this-ad .col h2 {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.44;
      color: #3B3B3A;
      font-family: "Jost", sans-serif;
      padding-bottom: 20px; }
    .why-this-ad .col h3 {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.44;
      color: #3B3B3A;
      font-family: "Jost", sans-serif;
      padding-bottom: 20px; }
    .why-this-ad .col li {
      padding-top: 8px;
      padding-left: 25px;
      position: relative;
      font-size: 16px; }
      .why-this-ad .col li:before {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 5px;
        background-color: #63636C;
        position: absolute;
        left: 10px;
        top: 17px; }
  .why-this-ad .infobox {
    width: 100%;
    order: 2;
    padding-top: 70px;
    line-height: 1.445; }
  .why-this-ad #info {
    visibility: hidden; }
    .why-this-ad #info > div {
      padding-bottom: 12px; }
      .why-this-ad #info > div:last-of-type {
        padding-bottom: 0px; }
    .why-this-ad #info strong {
      font-size: 16px; }
    .why-this-ad #info small {
      display: block;
      font-size: 12px; }
  .why-this-ad .manage {
    padding-top: 25px;
    margin-top: 35px;
    width: 100%;
    font-size: 14px;
    order: 3;
    line-height: 1.445;
    position: relative; }
    .why-this-ad .manage:before {
      content: '';
      display: block;
      width: calc(100% + 30px);
      height: 1px;
      border-radius: 5px;
      background-color: #5546FF;
      position: absolute;
      left: -15px;
      top: 0px; }
      @media screen and (max-width: 760px) {
        .why-this-ad .manage:before {
          width: calc(100%);
          left: 0; } }
    .why-this-ad .manage a {
      color: #5546FF; }
  .why-this-ad .col-appendix {
    width: calc(40% - 40px);
    margin-left: 40px;
    order: 1; }
    @media screen and (max-width: 760px) {
      .why-this-ad .col-appendix {
        display: none; } }
    .why-this-ad .col-appendix .element {
      width: 100%; }

.why-this-ad .loader {
  width: 32px;
  height: 32px;
  border: 3px solid #5546ff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-right: 25px; }

#loaderTimer {
  margin-left: 4px; }

.infobox > div small {
  display: block; }

.infobox .loader-wrapper {
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute; }

@keyframes rotation {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

[data-target="WPM"] {
  font-family: 'Poppins', sans-serif; }
  [data-target="WPM"] scrollbar-thumb {
    border-radius: 1px;
    background: #63636C; }
  [data-target="WPM"] ::-webkit-scrollbar-thumb {
    border-radius: 1px;
    background: #63636C; }
  [data-target="WPM"] .why-this-ad .manage:before {
    background-color: #E02020; }
  [data-target="WPM"] .why-this-ad .loader {
    border-color: #E02020;
    border-bottom-color: transparent; }
  [data-target="WPM"] .header-hamburger span {
    background-color: #63636C; }
  [data-target="WPM"] .header nav.header--nav ul li span a h2 {
    font-size: 14px;
    color: #63636C;
    font-weight: 400; }
  [data-target="WPM"] .tech-3 .st1 {
    fill: #63636C; }
  [data-target="WPM"] .header nav.header--nav {
    width: calc(92vw - 54px); }
  [data-target="WPM"] .header nav.header--nav:not(.__js_isActive) ul li span {
    background-color: #fff; }
  @media screen and (max-width: 760px) {
    [data-target="WPM"] .header nav.header--nav.__js_isActive,
    [data-target="WPM"] .header nav.header--nav.__js_isActive ul li:last-of-type span {
      background-color: #d1d1d1; }
    [data-target="WPM"] .header nav.header--nav ul li:last-of-type span a.__active h2 {
      color: #7b7b7b; } }
  [data-target="WPM"] body {
    font-family: 'Poppins', sans-serif; }
  [data-target="WPM"] .why-this-ad .col h2,
  [data-target="WPM"] .why-this-ad h3,
  [data-target="WPM"] .why-this-ad h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #63636C; }
  [data-target="WPM"] .header {
    border-bottom: 0;
    background-color: #f8f9fa; }
  [data-target="WPM"] .header--logo svg {
    height: 60%; }
    [data-target="WPM"] .header--logo svg path {
      fill: #E02020; }
  [data-target="WPM"] .why-this-ad .infobox strong {
    font-weight: 500; }
  [data-target="WPM"] .header-row {
    background-color: #F8F9FA; }
  [data-target="WPM"] .footer {
    background-color: #F8F9FA;
    background-image: none; }
  [data-target="WPM"] .footer-logo svg {
    width: 80px; }
  [data-target="WPM"] .footer-logo path {
    fill: #ED1C24; }
  [data-target="WPM"] .footer-links a {
    color: #2f2f2f; }
  [data-target="WPM"] .header nav.header--nav ul li span a:before {
    background-color: #d6d6d6; }
