.benefits {
    background-color: #5546ff !important;

    .wrapper {
        display: flex;
        justify-content: center;
        // padding: 40px 20px;

        @include max-breakpoint (700px) {
            display: block;
        }

        .title {
            color: #fff;
            font-family: $font-title;
            font-weight: bold;
        }

        .container {
            width: 67%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-top: 40px;
            margin-left: 35px;

            @include max-breakpoint (920px) {
                width: 83%;
            }

            @include max-breakpoint (700px) {
                width: 100%;
                // justify-content: center;
                margin-top: 25px;
                margin-left: 0;
            }


            .element {
                width: 45%;
                display: flex;
                margin-bottom: 35px;
                align-items: center;

                @include max-breakpoint (820px) {
                    width: 49%;
                }

                @include max-breakpoint (480px) {
                    width: 100%;
                    justify-content: center;
                }

                &:nth-of-type(odd) {
                    margin-right: 10px;

                    @include max-breakpoint (700px) {
                        margin-right: 0;
                    }
                }

                .img {
                    position: relative;
                    width: 85px;
                    height: 85px;

                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 50px;
                        height: 100%;
                        background-color: #3b31b2;
                        left: 50%;
                        top: 0;
                        transform: translateX(-50%);
                        clip-path: polygon(50% 0%, 100% 0, 50% 100%, 0 100%);
                        animation: bg-icons 12s infinite ease-in-out;
                    }

                    img {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 42px;
                        animation: icons 6s infinite linear;
                    }
                }

                p {
                    margin-left: 5px;
                    width: 180px;
                }
            }
        }
    }
}

@keyframes icons {
    0% {
        transform: translate(-50%, -50%);
    }

    25% {
        // transform: translateY(5px);
        transform: translate(-50%, -50%) scale(1.15);
    }

    50% {
        transform: translate(-50%, -50%);
    }

    75% {
        transform: translate(-50%, -50%) scale(1.15);
    }

    100% {
        transform: translate(-50%, -50%);
    }

}

@keyframes bg-icons {
    0% {
        // width: 40px;
        transform: translateX(-50%) scaleX(1);
    }

    25% {
        // width: 45px;
        transform: translateX(-50%) scaleX(-1);
    }

    50% {
        // width: 40px;
        transform: translateX(-50%) scaleX(1);
    }

    75% {
        // width: 45px;
        transform: translateX(-50%) scaleX(-1);
    }

    100% {
        // width: 40px;
        transform: translateX(-50%) scaleX(1);
    }
}