.about-tech {
    .wrapper {

        .container {
            display: flex;
            width: 80%;
            margin: 0 auto;
            justify-content: space-evenly;
            flex-wrap: wrap;
            overflow: hidden;

            @include max-breakpoint (1020px) {
                width: 90%;
            }

            @include max-breakpoint (850px) {
                width: 100%;
            }

            .element {
                width: 28%;
                text-align: center;
                opacity: 0;
                transition: 1s;

                @include max-breakpoint (780px) {
                    width: 46%;
                    margin-bottom: 20px;
                }

                @include max-breakpoint (450px) {
                    width: 75%;
                }

                img {
                    height: 150px;
                    max-width: 100%;
                }

                p {
                    color: #343440;
                    text-align: center;

                    span {
                        font-weight: bold;
                    }
                }

                &.__js_inView {
                    opacity: 1;
                }
            }
        }
    }
}

.tech-1 {
    .st0 {
        fill: #E9E9F3;
    }

    .st1 {
        fill: #5546FF;
    }

    .st2 {
        fill: #FFFFFF;
    }

    .st3 {
        fill: #3988C3;
    }

    .st4 {
        fill: #FF6F00;
    }

    .letter-1 {
        animation: letter-1 3 6s;
    }

    .letter-2 {
        animation: letter-2 3 6s;
    }

    .letter-3 {
        animation: letter-3 3 6s;
    }

    .letter-4 {
        animation: letter-4 3 6s;
    }

    .letter-5 {
        animation: letter-5 3 6s;
    }

    .letter-6 {
        animation: letter-6 3 6s;
    }

    .circle-grey {
        transform-origin: 600px 367px;
        animation: circle-xy infinite 12s ease-in-out;
    }

    .circle-blue {
        transform-origin: 100px 500px;
        animation: circle-y 10s infinite ease-in-out;
    }
}

.tech-2 {
    .st0 {
        fill: #E9E9F3;
    }

    .st1 {
        fill: #5546FF;
    }

    .st2 {
        fill: #FFFFFF;
    }

    .st3 {
        fill: none;
        stroke: #E9E9F3;
        stroke-width: 8;
        stroke-miterlimit: 10;
    }

    .arrow {
        animation: arrow-2 infinite 5s linear;
    }

    .circle-blue {
        transform-origin: 100px 500px;
        animation: circle-x 10s infinite ease-in-out;
    }
}

.tech-3 {
    transform: translateY(17px);

    .st0 {
        fill: #E9E9F3;
    }

    .st1 {
        fill: #5546FF;
    }

    .st2 {
        fill: #FFFFFF;
    }

    .bar {
        transform: translateY(-50px);
    }

    .arrow {
        transform-origin: 400px 450px;
        animation: arrow-3 5s infinite;
    }

    .circle-grey {
        transform-origin: 600px 367px;
        animation: circle-rotate 20s infinite;
    }

    .circle-blue {
        transform-origin: 100px 500px;
        animation: circle-y 12s infinite ease-in-out;
    }
}

@keyframes circle-x {
    0% {
        transform: translateX(0)
    }

    50% {
        transform: translateX(680px);
    }

    100% {
        transform: translateX(0)
    }
}

@keyframes circle-y {
    0% {
        transform: translateY(0)
    }

    50% {
        transform: translateY(-300px);
    }

    100% {
        transform: translateY(0)
    }
}

@keyframes circle-xy {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(-430px, 530px);
    }

    100% {
        transform: translate(0, 0);
    }
}

@keyframes circle-rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes arrow-2 {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(15px);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(-15px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes arrow-3 {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.15);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes letter-1 {
    0% {
        opacity: 0;
    }

    16.66% {
        opacity: 1;
    }

    33.33% {
        opacity: 1;
    }

    49.99% {
        opacity: 1;
    }

    66.65% {
        opacity: 1;
    }

    83.31% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes letter-2 {
    0% {
        opacity: 0;
    }

    16.66% {
        opacity: 0;
    }

    33.33% {
        opacity: 1;
    }

    49.99% {
        opacity: 1;
    }

    66.65% {
        opacity: 1;
    }

    83.31% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes letter-3 {
    0% {
        opacity: 0;
    }

    16.66% {
        opacity: 0;
    }

    33.33% {
        opacity: 0;
    }

    49.99% {
        opacity: 1;
    }

    66.65% {
        opacity: 1;
    }

    83.31% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes letter-4 {
    0% {
        opacity: 0;
    }

    16.66% {
        opacity: 0;
    }

    33.33% {
        opacity: 0;
    }

    49.99% {
        opacity: 0;
    }

    66.65% {
        opacity: 1;
    }

    83.31% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes letter-5 {
    0% {
        opacity: 0;
    }

    16.66% {
        opacity: 0;
    }

    33.33% {
        opacity: 0;
    }

    49.99% {
        opacity: 0;
    }

    66.65% {
        opacity: 0;
    }

    83.31% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes letter-6 {
    0% {
        opacity: 0;
    }

    16.66% {
        opacity: 0;
    }

    33.33% {
        opacity: 0;
    }

    49.99% {
        opacity: 0;
    }

    66.65% {
        opacity: 0;
    }

    83.31% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}