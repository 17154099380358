@import "_reset.scss";
@import "_vars.scss";

//mixins
// do rwd
@mixin max-breakpoint($point) {
	@media screen and (max-width: $point) {
		@content;
	}
}


@import '_main.scss';
@import '_global.scss';
@import '__header.scss';
@import '__owlslider.scss';
@import '__popup.scss';
@import '__footer.scss';
@import '__section1.scss';
@import '__section2.scss';
@import '__section3.scss';
@import '__section4.scss';
@import '__form.scss';
@import '__contact.scss';
@import '__about1.scss';
@import '__benefits.scss';
@import '__target.scss';
@import '__list.scss';
@import '__why_this_ad.scss';