.section-wrapper.partner {
    background-color: white;
    padding-top: 20px;

    .wrapper {
        text-align: center;
        padding-top: 0;

        img {
            margin: 30px 0;
        }
    }

    .main-text {
        display: flex;
        justify-content: center;
        align-items: baseline;
        margin-bottom: 20px;

        @include max-breakpoint(740px) {
            flex-direction: column;
            align-items: center;
        }

        img {
            margin-left: 10px;
            transform: translateY(20%);
        }
    }

    p {
        color: #869791;
    }

}