html {
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
}


body {
  font-family: $font-main;
  font-size: 1.4rem;
  width: 100%;
  overflow-x: hidden;
  color: #fff;
  background-color: #fff;
  // background-image: url(https://ikea-2020.wpcdn.pl/img/bg-1.png);
  background-size: cover;
  margin-top: 90px;
  // user-select: none;

  @include max-breakpoint (760px) {
    margin-top: $headerMobileHeight;
    background-position: 50% 60px;
  }

}


scrollbar {
  width: 8px;
  background: rgba(119, 119, 119, .1);
}

scrollbar-track {
  background-color: rgba(5, 5, 5, .1);
  border-radius: 0px;
}

scrollbar-thumb {
  border-radius: 1px;
  background: $domain-color;
}

::-webkit-scrollbar {
  width: 8px;
  background: rgba(119, 119, 119, .1);
  // background-image: url(https://ikea-2020.wpcdn.pl/img/suwak.png);
}

::-webkit-scrollbar-track {
  background-color: rgba(5, 5, 5, .1);
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background: $domain-color;
}



.outer {
  position: relative;
  overflow: hidden;
  padding: 0 3%;
  // margin-top: -60px;

  @include max-breakpoint($point1 + 250px) {
    margin-top: 0;
  }

  @include max-breakpoint ($point2 + 120px) {
    padding: 0 4%;
  }

}

.wrapper {
  // max-width: $layoutWidth;
  margin: 0px auto;
  position: relative;
  box-sizing: border-box;

  & .section-products-title {
    margin-bottom: 25px;
    font-weight: 900;
    font-size: 50px;
    color: $main-color;
    text-align: center;
    font-family: $font-title;
    font-weight: normal;

    @include max-breakpoint(640px) {
      font-size: 40px;
    }
  }
}

.section-title {
  text-transform: uppercase;
  margin-bottom: 25px;
  font-weight: 900;
  font-size: 50px;
  color: #000;
  font-family: "casablanca-urw";
}

.hr-desc {
  padding: 0 !important;
  font-size: 14px;
  color: #222222;
  border-top: 3px solid black;
  text-transform: uppercase;
  margin-bottom: -5px;
}

.section-wrapper {
  &:nth-of-type(n) {
    background-color: #fff;
  }

  &:nth-of-type(2n) {
    background-color: #efeef6;
  }

  .wrapper {
    max-width: 1260px;
    margin: 0 auto;
    padding: 70px 20px;

    @include max-breakpoint(500px) {
      padding: 50px 20px;
    }

    .title {
      font-size: 30px;
      color: #343440;
      text-align: center;
      font-weight: 500;
      font-family: $font-title;

      @include max-breakpoint(450px) {
        font-size: 26px;
      }

      @include max-breakpoint(380px) {
        font-size: 24px;
      }


      &.bold {
        font-weight: 600;
      }

      span {
        font-weight: 700;
      }
    }
  }
}