.contact {
    // background-image: url(https://wpartner-2020.wpcdn.pl/img/form-bg.png);
    // background-size: contain;
    // background-size: contain;
    // background-position: left bottom;
    // background-repeat: no-repeat;
    background-color: #efeef6 !important;

    .wrapper {
        // padding: 50px 20px;

        .container {
            display: flex;
            // justify-content: center;
            position: relative;
            flex-wrap: wrap;
            justify-content: space-evenly;

            @include max-breakpoint(760px) {
                justify-content: flex-start;
            }

            p {
                color: #737386;
                // position: absolute;
                // left: 100px;
                // top: 33%;
                // transform: translateY(-50%);

                // @include max-breakpoint(1000px) {
                //     left: 50px;
                // }

                // @include max-breakpoint(750px) {
                //     left: 10px;
                // }

                // @include max-breakpoint(600px) {
                //     left: 10px;
                //     top: auto;
                //     bottom: -30px;
                // }
            }

            .form {
                width: 50%;
                // margin-left: 100px;

                @include max-breakpoint(800px) {
                    width: 60%;
                    // margin-left: 150px;
                }

                @include max-breakpoint(760px) {
                    width: 100%;
                }

                @include max-breakpoint(600px) {
                    // margin-left: 0;
                    width: 100%;
                }

                h2 {
                    color: #353542;
                    font-family: $font-title;
                    margin-bottom: 30px;
                    font-size: 26px;
                }

                form {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    position: relative;
                    padding-bottom: 30px;

                    .error {
                        font-size: 10px;
                        font-weight: 700;
                        color: #a94442;
                        margin-left: 85px;
                        display: none;
                        width: 100%;
                        position: absolute;
                        bottom: 0;
                        transform: translateY(100%);

                        &.error-checkbox {
                            @include max-breakpoint(600px) {
                                margin-left: 35px;
                            }
                        }
                    }

                    .blank {
                        width: 100%;
                        margin-bottom: 20px;
                        color: #333247;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        position: relative;


                        p {
                            font-size: 15px;
                            width: 85px;
                            display: inline-block;
                        }

                        &.desc {
                            width: 100%;
                            align-items: flex-start;

                        }

                        input {
                            width: 200px;
                            max-width: calc(100% - 94px);
                            border: none;
                            height: 28px;
                            outline: none;
                            margin-top: 5px;
                            border-radius: 1px;
                            padding-left: 5px;
                            border: 1px solid #cdcae1;
                            transition: .5s;
                            font-family: 'Varta', sans-serif;
                            font-size: 18px;
                            color: #333247;

                            &:focus {
                                border: 1px solid #5243ff;
                            }
                        }

                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        input[type=number] {
                            -moz-appearance: textfield;
                        }

                        textarea {
                            width: auto;
                            height: 120px;
                            flex-grow: 1;
                            resize: none;
                            outline: none;
                            border: none;
                            margin-top: 5px;
                            font-family: $font-main;
                            border-radius: 1px;
                            padding-left: 5px;
                            border: 1px solid #cdcae1;
                            transition: .5s;

                            &:focus {
                                border: 1px solid #5243ff;
                            }
                        }
                    }

                    .submit {
                        // width: 130px;
                        // height: 40px;
                        background-color: #5243ff;
                        color: #fff;
                        border: 0;
                        outline: 0;
                        padding: 10px 30px;
                        padding-bottom: 8px;
                        font-weight: bold;
                        cursor: pointer;
                        transition: .5s;
                        font-family: 'Varta', sans-serif;
                        font-size: 16px;
                        margin-left: 85px;
                        margin-top: 30px;

                        @include max-breakpoint(600px) {
                            margin-left: 0;
                        }

                        &:hover {
                            // background-color: #353542;
                            // color: #5243ff;
                            transform: scale(1.05);
                        }
                    }
                }
            }

            .contact-details {
                margin-left: 60px;

                @include max-breakpoint(800px) {
                    margin-left: 0;
                    order: -1;
                    margin-bottom: 20px;
                    width: 60%;
                }

                h2 {
                    font-size: 20px;
                    color: #6e6e80;
                    font-family: 'Jost', sans-serif;
                    font-weight: normal;
                    margin-bottom: 50px;
                }

                p {
                    color: #3d3d51;
                    margin-bottom: 25px;
                    position: relative;
                    margin-left: 18px;
                    font-size: 17px;

                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        left: -17px;
                        top: 3px;
                        width: 10px;
                        height: 12px;
                        background-image: url(https://wpartner-2020.wpcdn.pl/img/list.png);
                        background-size: cover;
                    }

                    span {
                        font-weight: bold;

                        &.blue {
                            color: #5546ff;
                        }
                    }
                }
            }
        }
    }
}

.rodo--container {
    position: relative;
    margin-top: 6px;
}

.rodo--label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #000;
    font-weight: 300;
    font-size: 12px;
    margin-left: 85px;

    @include max-breakpoint(600px) {
        margin-left: 0;
    }

    &:nth-of-type(3) {
        margin-bottom: 5px;
    }

    &:hover {
        .rodo--label__checkmark {
            background-color: #ddd;
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked {
            &+.rodo--label__checkmark {
                background-color: #5243ff;

                &::after {
                    display: block;
                }
            }

        }
    }

    .rodo--label__checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        border: 1px solid black;
        transition: .5s;

        &::after {
            content: "";
            position: absolute;
            display: none;
            left: 8px;
            top: 4px;
            width: 7px;
            height: 11px;
            border: solid #fff;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg)
        }
    }

    .main-form__label--error {
        color: #a94442;
        font-weight: 700;
    }
}

.g-recaptcha {
    width: 100%;
    margin-left: 85px;
    margin-top: 15px;

    @include max-breakpoint(600px) {
        margin-left: 0;
    }
}

.rodo--info {
    margin-left: 85px;
    font-size: 12px;
    color: #000 !important;

    @include max-breakpoint(600px) {
        margin-left: 0;
    }

    span {
        text-decoration: underline;
        cursor: pointer;
    }
}

.rodo--info--full {
    margin-left: 85px;
    font-size: 12px;
    color: #000 !important;

    @include max-breakpoint(600px) {
        margin-left: 0;
    }

    span {
        text-decoration: underline;
        cursor: pointer;
    }

    a {
        color: #ec1c24;
    }

    &.hidden {
        display: none;
    }
}
