.map {
    .wrapper {
        padding: 0;

        @include max-breakpoint(1280px) {
            padding: 0 20px;
        }

        .container {
            display: flex;
            height: 300px;

            @include max-breakpoint(600px) {
                flex-wrap: wrap;
                height: auto;
            }

            .img {
                width: 35%;

                @include max-breakpoint(600px) {
                    width: 100%;
                    height: 250px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .mapouter {
                width: 65%;
                height: 100%;

                @include max-breakpoint(600px) {
                    width: 100%;
                    height: 300px;
                }

                .gmap_canvas {
                    width: 100%;
                    height: 100%;

                    #gmap_canvas {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
.g-recaptcha {
    > div {
        left: 50%;
        position: relative;
        transform: translateX(-50%);
    }
}
