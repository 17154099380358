/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.wrapper {
    .owl-title {
        color: #869791;
        text-align: center;
        font-family: $font-title;
        font-weight: 450;
        margin-bottom: 15px;
    }
}

.owl-carousel,
.owl-carousel .owl-item {
    -webkit-tap-highlight-color: transparent;
    position: relative;
}

.owl-carousel {
    display: none;
    width: 100%;
    z-index: 1;
}

.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
    display: block;
    width: 100%;
    object-fit: contain;
    object-position: center;
    height: 100%;
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
    display: none;
}

.no-js .owl-carousel,
.owl-carousel.owl-loaded {
    display: block;
}

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
    background: 0 0;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block;
}

.owl-carousel.owl-hidden {
    opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
    visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.owl-carousel.owl-grab {
    cursor: move;
    cursor: grab;
}

.owl-carousel.owl-rtl {
    direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
    float: right;
}

.owl-carousel .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
    z-index: 0;
}

.owl-carousel .owl-animated-out {
    z-index: 1;
}

.owl-carousel .fadeOut {
    animation-name: fadeOut;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.owl-height {
    transition: height 0.5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 0.4s ease;
}

.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
    max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d;
}

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
    display: none;
}

/**
  * Owl Carousel v2.3.4
  * Copyright 2013-2018 David Deutsch
  * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
  */
.owl-theme .owl-dots,
.owl-theme .owl-nav {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav {
    margin-top: 10px;
}

.owl-theme .owl-nav [class*="owl-"] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 5px;
    display: inline-block;
    cursor: pointer;
}

// .owl-theme .owl-nav [class*="owl-"]:hover {
//   background: #869791;
//   color: #fff;
//   text-decoration: none;
// }
.owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default;
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 10px;
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
}

.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #d6d6d6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 0.2s ease;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #869791;
}

.owl-carousel {
    margin: 5% 0 2%;

    @include max-breakpoint($point3) {
        // width: 90%;
        // max-width: 400px;
        margin: 3% auto 20px;
    }
}

.owl-item .owl-img {
    position: relative;
    // padding-top: calc(128%);
    padding-top: calc(108%);

    @include max-breakpoint($point1 - 56) {
        padding-top: calc(122%);
    }

    @include max-breakpoint($point3) {
        padding-top: calc(112%);
    }
}

.owl-item .item img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: scale(1);
    transition: all 500ms ease;
}

.owl-item.center {
    z-index: 3;
}

.owl-stage-outer {
    // overflow: visible !important;
}

.owl-dots {
    display: none;
}

.owl-theme .owl-nav {
    margin-top: 0px;
    position: absolute;
    width: 27%;
    height: 0%;
    margin: 0px auto;
    left: 0;
    right: 0;
    top: 180px;

    // top: 0;
    // @include max-breakpoint($point-nav + 200) {
    //     top: 150px;
    // }

    @include max-breakpoint($point0 + 40) {
        width: 44%;
        top: 200px;
    }

    // @include max-breakpoint($point1) {
    //   top: 200px;
    // }
    @include max-breakpoint($point2 + 100) {
        top: 150px;
    }

    @include max-breakpoint($point3) {
        width: 100%;
        top: 200px;
    }

    button.owl-prev,
    button.owl-next {
        cursor: pointer;
        transition: all 200ms ease;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        padding: 0;
        outline: none;
        width: 58px;
        height: 51px;
        background-image: url(https://xbox-2020.wpcdn.pl/img/owl-arrow.png);
        // border-top-right-radius: 50px;
        // border-bottom-right-radius: 50px;
        // background-color: $main-color;

        &:hover {
            // background-color: $domain-color;
            background-image: url(https://xbox-2020.wpcdn.pl/img/owl-arrow-hover.png);
        }

        >span {
            opacity: 0;
        }

        // &:before {
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   content: "";
        //   position: absolute;
        //   top: 50%;
        //   left: 60%;
        //   transform: translate(-50%, -50%);
        //   transition: all 0.2s ease;
        //   height: 11px;
        //   width: 13px;
        //   background-image: url(http://oppo-2019.wpcdn.pl/img/static/arr-r.png);
        //   background-size: contain;
        //   background-position: center;
        //   background-repeat: no-repeat;
        //   z-index: 2;
        // }
    }

    button.owl-prev {
        left: 0;
        transform: translate(-50%, -50%) scale(-1);
    }

    button.owl-next {
        // right: 0;
        transform: translate(50%, -50%);
    }
}

.owl-desc {
    // position: absolute;
    //transform: translateY(100%);
    opacity: 0;
}

.center .owl-desc {
    opacity: 1;
    // transition: opacity 400ms ease 200ms;
    // margin-top: calc(20%);
    // position: relative;
    margin-left: -15%;
    width: 130%;

    // letter-spacing: 1px;
    // // padding: 20px;
    // padding-bottom: 0;
    // box-sizing: border-box;
    // @include max-breakpoint($point1 - 56) {
    //   margin-top: calc(15.2% + 7px);
    // }
    // @include max-breakpoint($point3) {
    //   width: 100%;
    //   margin-left: -0%;
    //   margin-top: 0;
    // }
    h4 {
        font-size: 16px;
        font-weight: bold;
        margin-top: 12px;
    }

    p {
        width: 100%;
        line-height: 1.6;
        font-size: 14px;
        // color: $grey-light-color;
        padding-top: 8px;
    }
}

.owl-carousel .owl-nav.disabled {
    display: none !important;
}

// 3 ITEM

.owl-carousel.owl-logo.owl-theme {
    margin: 3% auto 50px;
    width: 80%;

    &:nth-of-type(2) {
        margin-bottom: 0;
    }

    @include max-breakpoint(500px) {
        width: 100%;
    }

    .owl-stage-outer {
        overflow: hidden;
    }

    .owl-nav {
        width: 45.5%;
        top: 300px;

        @include max-breakpoint(1220px) {
            width: 45.2%;
        }

        @include max-breakpoint(80px) {
            width: 45%;
        }

        @include max-breakpoint(880px) {
            top: 270px;
            width: 47%;
        }

        @include max-breakpoint($point3) {
            top: 210px;
            width: 63.5%;
        }

        @include max-breakpoint(600px) {
            width: 64%;
        }

        @include max-breakpoint(520px) {
            width: 65.5%;
        }

        @include max-breakpoint(480px) {
            top: 210px;
            width: 86%;
        }

        @include max-breakpoint(380px) {
            width: 75%;
        }

        button.owl-prev {}

        button.owl-next {
            right: 0;
        }
    }

    .owl-item {

        // visibility: hidden;
        // transition: none;
        // opacity: 0;
        &.active {
            // visibility: visible;
            // opacity: 1;
        }
    }

    .owl-item .owl-img {
        padding-top: 0;
        height: 80px;

        img {
            position: relative;
            height: 80px;
            max-width: 90%;
            margin: 0 auto;
            // filter: brightness(0.8);
        }
    }

    // .owl-item.center {
    //     div.item {
    //         div.owl-img {
    //             img {
    //                 filter: brightness(1);
    //                 transform: scale(1.4);
    //                 display: block;

    //                 @include max-breakpoint($point3) {
    //                     transform: scale(1.1);
    //                 }
    //             }
    //         }

    //         .owl-desc {
    //             transition: none;
    //             opacity: 1;
    //             margin-top: 100px;
    //             position: relative;
    //             margin-left: -20%;
    //             width: 140%;
    //             letter-spacing: 1px;
    //             // padding: 20px;
    //             padding-bottom: 0;
    //             box-sizing: border-box;

    //             @include max-breakpoint(880px) {
    //                 margin-top: 80px;
    //             }

    //             @include max-breakpoint($point3) {
    //                 width: 110%;
    //                 margin-left: -5%;
    //                 margin-top: 30px;
    //             }

    //             h4 {
    //                 padding-left: 32px;
    //                 padding-bottom: 8px;
    //                 font-weight: bold;
    //                 font-size: 14px;
    //                 line-height: 1.4;
    //                 // color: $black-color;
    //                 position: relative;
    //                 transition: none;
    //                 box-sizing: border-box;
    //                 margin-top: 0px;

    //                 &:before {
    //                     display: block;
    //                     position: absolute;
    //                     content: "";
    //                     top: 5px;
    //                     left: 9px;
    //                     width: 17px;
    //                     height: 11px;
    //                     // background-color: $alternate-color;
    //                     background-image: url(https://xbox-2020.wpcdn.pl/img/img-float.png);
    //                 }
    //             }

    //             p {
    //                 transition: none;
    //                 padding-left: 32px;
    //                 color: #737373;
    //                 font-size: 13px;
    //                 font-weight: 400;
    //                 padding-top: 0;
    //                 letter-spacing: normal;
    //                 box-sizing: border-box;
    //             }
    //         }
    //     }
    // }
}