@import url('https://fonts.googleapis.com/css2?family=Varta:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,700;0,900;1,600&display=swap');


//fonts
$font-main: 'Varta',
sans-serif;
/*body*/
$font-headers: 'Jost',
sans-serif;
$font-title: 'Jost',
sans-serif;

//variables
$main-color: #0058a3;
$domain-color: #5546ff;
$alternate-color: #6da775;
$grey-color: #9a9a9a;
$white-color: #fff;
$bg-black: #000;
$bg-white: #fff;

//breakpoints
$point0: 1580px;
$point1: 1024px;
$point2: 768px;
$point3: 640px;
$point4: 320px;

$sliderPoint: 1100px;

//vars
$layoutWidth: 1920px;
$layoutInternalWidth : 1000px;
$headerHeight: 100px;
$headerMobileHeight: 60px;
$gutter: 38px;
$articlePoint: 700px;
$gutterMobile : 4%;
