.why-this-ad {
    color: #63636C;    
    min-height: calc(100vh - 90px - 202px);
    
    @include max-breakpoint(1920px) {
        min-height: calc(100vh - 90px - 2vw - 206px);
    }
//    @include max-breakpoint(760px) {
//        min-height: calc(100vh - 60px - 210px);
//    }
    
    h2 {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.44;
        color: #3B3B3A;
        font-family: $font-headers;
        padding-bottom: 20px;
    }

    h3 {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.44;
        color: #3B3B3A;
        font-family: $font-headers;
        padding-bottom: 20px;
    }

    p {
        padding-bottom: 10px;
    }

    a {
        color: #5546FF;
    }

    .wrapper {
        max-width: 930px;
    }

    .container {
        display: flex;
        // justify-content: center;
        position: relative;
        flex-wrap: wrap;
        justify-content: space-evenly;

        @include max-breakpoint(760px) {
            justify-content: flex-start;
        }
    }



    .col {
        width: 60%;
        // margin-left: 100px;
        order: 0;


        @include max-breakpoint(760px) {
            width: 100%;
        }

        @include max-breakpoint(600px) {
            // margin-left: 0;
            width: 100%;
        }

        h2 {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.44;
            color: #3B3B3A;
            font-family: $font-headers;
            padding-bottom: 20px;
        }

        h3 {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.44;
            color: #3B3B3A;
            font-family: $font-headers;
            padding-bottom: 20px;
        }

        li {
            padding-top: 8px;
            padding-left: 25px;
            position: relative;
            font-size: 16px;

            &:before {
                content: '';
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 5px;
                background-color: #63636C;
                position: absolute;
                left: 10px;
                top: 17px;
            }
        }
    }


    .infobox {
        width: 100%;
        order: 2;
        padding-top: 70px;
        line-height: 1.445;

    }

    #info {
        visibility: hidden;
        > div {
            padding-bottom: 12px;

            &:last-of-type {
                padding-bottom: 0px;
            }
        }

        strong {
            font-size: 16px;
        }

        small {
            display: block;
            font-size: 12px;
        }

    }

    .manage {
        padding-top: 25px;
        margin-top: 35px;
        width: 100%;
        font-size: 14px;
        order: 3;
        line-height: 1.445;
        position: relative;

        &:before {
            content: '';
            display: block;
            width: calc(100% + 30px);
            height: 1px;
            border-radius: 5px;
            background-color: #5546FF;
            position: absolute;
            left: -15px;
            top: 0px;

            @include max-breakpoint(760px) {
                width: calc(100%);
                left: 0;
            }
        }


        a {
            color: #5546FF;
        }
    }

    .col-appendix {
        width: calc(40% - 40px);
        margin-left: 40px;
        order: 1;


        @include max-breakpoint(760px) {
            display: none;
        }

        .element {
            width: 100%;
        }

    }

}

.why-this-ad .loader {
    width: 32px;
    height: 32px;
    border: 3px solid #5546ff;

    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin-right: 25px;
}
#loaderTimer {
    margin-left: 4px;   
}
.infobox > div small {
    display: block;
}

.infobox .loader-wrapper {
    font-size: 12px;
    display: flex;
    justify-content: flex-start;    
    align-items: center;
    position: absolute;
    
}


@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

[data-target="WPM"] {

    scrollbar-thumb {
        border-radius: 1px;
        background: #63636C;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 1px;
        background: #63636C;
    }

    font-family: 'Poppins',
    sans-serif;


    .why-this-ad .manage:before {
        background-color: #E02020;
    }

    .why-this-ad .loader {
        border-color: #E02020;
        border-bottom-color: transparent;
    }

    .header-hamburger span {
        background-color: #63636C;
    }

    .header nav.header--nav ul li span a h2 {
        font-size: 14px;
        color: #63636C;
        font-weight: 400;
    }

    .tech-3 .st1 {
        fill: #63636C;
    }

    .header nav.header--nav {
        width: calc(92vw - 54px);
    }

    .header nav.header--nav:not(.__js_isActive) ul li span {
        background-color: #fff;
    }

    @include max-breakpoint(760px) {

        .header nav.header--nav.__js_isActive,
        .header nav.header--nav.__js_isActive ul li:last-of-type span {
            background-color: #d1d1d1;
        }

        .header nav.header--nav ul li:last-of-type span a.__active h2 {
            color: #7b7b7b;
        }
    }

    body {
        font-family: 'Poppins', sans-serif;
    }

    .why-this-ad .col h2,
    .why-this-ad h3,
    .why-this-ad h2 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        color: #63636C;
    }

    .header {
        border-bottom: 0;
        background-color: #f8f9fa;
    }

    .header--logo svg {
        height: 60%;

        path {
            fill: #E02020;
        }
    }

    .why-this-ad .infobox strong {
        font-weight: 500;
    }

    .header-row {
        background-color: #F8F9FA;
    }

    .footer {
        background-color: #F8F9FA;
        background-image: none;
    }

    .footer-logo svg {
        width: 80px;
    }

    .footer-logo path {
        fill: #ED1C24;
    }

    .footer-links a {
        color: #2f2f2f;
    }
    .header nav.header--nav ul li span a:before {
        background-color: #d6d6d6;
    }
}
