.section-wrapper.signs {
    background-color: #5546ff !important;

    .wrapper {
        h2 {
            width: 88%;
            text-align: left;
            margin: 0 auto;
            margin-bottom: 60px;
            font-family: 'Jost';
            font-size: 26px;
        }

        .container {
            display: flex;
            width: 85%;
            margin: 0 auto;

            @include max-breakpoint(880px) {
                flex-wrap: wrap;
            }

            @include max-breakpoint(730px) {
                width: 90%;
            }

            @include max-breakpoint(425px) {
                width: 100%;
            }

            .text {
                width: 40%;
                margin-right: 50px;

                li {
                    position: relative;
                    margin-bottom: 1em;
                    &:before {
                        content: "";
    display: block;
    position: absolute;
    left: -17px;
    top: 3px;
    width: 10px;
    height: 12px;
    background-image: url(https://wpartner-2020.wpcdn.pl/img/list_demand.png);
    background-size: contain;
    background-repeat: no-repeat; 
                    }
                }

                @include max-breakpoint(1025px) {
                    width: 30%;
                }

                @include max-breakpoint(880px) {
                    width: 100%;
                    margin-right: 0;
                    margin-left: 20px;
                    padding-bottom: 20px;
                }

                h2 {
                    font-family: $font-title;
                    margin-bottom: 20px;
                    font-size: 28px;
                    color: #fff;
                }

                p {
                    margin-bottom: 20px;
                    color: #fff;
                }
            }

            .signs {
                width: 68%;

                @include max-breakpoint(1025px) {
                    width: 70%;
                }

                @include max-breakpoint(880px) {
                    width: 100%;
                }

                .icons {
                    display: flex;
                    justify-content: flex-start;

                    @include max-breakpoint(880px) {
                        margin-top: 0;
                        justify-content: center;
                    }

                    @include max-breakpoint(425px) {
                        flex-wrap: wrap;
                    }

                    .icon {
                        text-align: center;
                        margin: 0 10px;
                        width: 115px;

                        @include max-breakpoint(425px) {
                            width: calc(50% - 20px);
                        }

                        .img {
                            position: relative;
                            height: 60px;

                            &:before {
                                content: "";
                                display: block;
                                position: absolute;
                                width: 40px;
                                height: 100%;
                                background-color: #3b31b2;
                                left: 50%;
                                top: 0;
                                transform: translateX(-50%);
                                clip-path: polygon(50% 0%, 100% 0, 50% 100%, 0 100%);
                                animation: bg-icons 12s infinite ease-in-out;
                            }

                            img {
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                max-width: 38px;
                                animation: icons 6s infinite linear;
                            }
                        }

                        p {
                            color: #fff;
                            text-align: center;
                            margin-top: 15px;
                        }
                    }
                }

                .desc {
                    display: flex;
                    color: #333247;
                    margin-top: 30px;

                    @include max-breakpoint(450px) {
                        flex-wrap: wrap;
                    }


                    .label {
                        margin-top: 6px;
                        color: #fff;
                    }

                    .desc-shortcuts {
                        margin-left: 20px;
                        color: #fff;

                        @include max-breakpoint(450px) {
                            margin-left: 0;
                            margin-top: 10px;
                        }

                        .shortcuts {
                            display: flex;
                            margin-bottom: 20px;
                            align-items: center;

                            p {
                                background-color: #3b31b2;
                                border-radius: 50%;
                                margin-right: 30px;
                                height: 35px;
                                width: 35px;
                                text-align: center;
                                line-height: 38px;
                                color: #fff;
                                animation: text 8s infinite ease-in-out;

                                @include max-breakpoint(450px) {
                                    margin-right: 20px;
                                }
                            }
                        }

                        p {
                            color: #c4bfff;
                        }
                    }
                }
            }
        }
    }
}

@keyframes icons {
    0% {
        transform: translate(-50%, -50%);
    }

    25% {
        // transform: translateY(5px);
        transform: translate(-50%, -50%) scale(1.15);
    }

    50% {
        transform: translate(-50%, -50%);
    }

    75% {
        transform: translate(-50%, -50%) scale(1.15);
    }

    100% {
        transform: translate(-50%, -50%);
    }

}

@keyframes text {
    0% {
        transform: scale(1);
    }

    25% {
        // transform: translateY(5px);
        transform: scale(0.9);
    }

    50% {
        transform: scale(1);
    }

    75% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes bg-icons {
    0% {
        // width: 40px;
        transform: translateX(-50%) scaleX(1);
    }

    25% {
        // width: 45px;
        transform: translateX(-50%) scaleX(-1);
    }

    50% {
        // width: 40px;
        transform: translateX(-50%) scaleX(1);
    }

    75% {
        // width: 45px;
        transform: translateX(-50%) scaleX(-1);
    }

    100% {
        // width: 40px;
        transform: translateX(-50%) scaleX(1);
    }
}