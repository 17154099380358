.section-wrapper.graphs {
    .wrapper {
        .container {
            display: flex;
            width: 80%;
            margin: 0 auto;
            margin-top: 60px;
            justify-content: space-evenly;

            @include max-breakpoint(730px) {
                flex-wrap: wrap;
                margin-top: 30px;
                width: 90%;
            }

            .element {
                // width: 50%;
                margin: 0 25px;

                &:nth-of-type(2) {
                    margin-right: 0;
                }

                @include max-breakpoint(730px) {
                    width: 100%;
                    text-align: center;

                    &:nth-of-type(2) {
                        margin: 0 25px;
                    }
                }

                h3 {
                    font-family: $font-title;
                    font-size: 24px;
                    color: #343440;
                    margin-bottom: 30px;
                    text-align: left;
                }

                &:nth-of-type(2) {
                    h3 {
                        color: #5d4ffd;

                        @include max-breakpoint(730px) {
                            margin-top: 20px;
                        }
                    }

                }

                ul {
                    text-align: left;

                    li {
                        position: relative;
                        color: #3d3d51;
                        margin-bottom: 25px;
                        font-size: 15px;

                        &:before {
                            content: "";
                            display: block;
                            position: absolute;
                            left: -17px;
                            top: 3px;
                            width: 10px;
                            height: 12px;
                            background-image: url(https://wpartner-2020.wpcdn.pl/img/list.png);
                            background-size: cover;
                        }
                    }
                }

                img {
                    max-width: 100%;
                    transition: .5s;
                    transform: translateY(-50%);
                    opacity: 0;
                    margin: 40px 0;

                    // &.__js_inView {
                    //     transform: translateX(0);
                    //     opacity: 1;
                    // }
                }

                &.__js_inView {
                    img {
                        transform: translateY(0);
                        opacity: 1;
                    }
                }
            }
        }
    }
}