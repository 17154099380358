.header {
    background-color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    height: 90px;
    border: 0;
    outline: 0;
    z-index: 7;
    border-bottom: 1px solid #848497;

    @include max-breakpoint(760px) {
        height: $headerHeight - 40px;
    }

    > .wrapper {
        max-width: 1730px;
        box-sizing: border-box;
        margin: 0 auto;
        height: 100%;
        position: static;
    }

    nav.header--nav {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        width: calc(92vw - 280px);
        max-width: 1440px;

        ul {
            display: flex;
            height: 100%;
            width: 100%;

            @include max-breakpoint(760px) {
                flex-direction: column;
                width: 100%;
            }

            li {
                &:nth-last-of-type(1) {
                    margin-left: auto;
                    color: #d1d1dd;

                    @include max-breakpoint(760px) {
                        margin-left: 0;
                        color: transparent;
                    }

                    span {
                        @include max-breakpoint(760px) {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: #4037b0;
                        }

                        a {
                            @include max-breakpoint(760px) {
                                background-color: transparent;
                                width: 50%;
                            }

                            &:hover {
                                @include max-breakpoint(760px) {
                                    background-color: #fff;
                                }
                            }

                            &.__active {
                                &:hover {
                                    cursor: unset;

                                    @include max-breakpoint(760px) {
                                        background-color: transparent;
                                    }

                                    &:before {
                                        display: none;
                                    }
                                }

                                h2 {
                                    color: #d1d1dd;

                                    @include max-breakpoint(760px) {
                                        color: #241f55;
                                    }
                                }
                            }

                            h2 {
                                font-size: 13px;
                            }
                        }
                    }
                }

                span {
                    height: 100%;
                    display: block;

                    a {
                        height: 100%;

                        h2 {
                            font-size: 14px;
                            color: #7e7e92;
                            transition: .3s;

                            @include max-breakpoint(760px) {
                                line-height: normal;
                                padding: 10px;
                                color: #fff;
                            }
                        }

                        &:before {
                            content: "";
                            display: block;
                            position: absolute;
                            left: 50%;
                            bottom: 2px;
                            background-color: #5546ff;
                            width: 0;
                            height: 2px;
                            transition: .3s;
                            transform: translateX(-50%);
                            opacity: 0;
                        }
                    }
                }
            }
        }

        a {
            display: inline-block;
            vertical-align: middle;
            font-size: 20px;
            font-weight: bold;
            position: relative;
            box-sizing: border-box;
            z-index: 2;
            color: #2d2d2d;
            transition: all 170ms ease;
            margin: 0 10px;
            text-transform: uppercase;

            &:hover {
                &::before {
                    background-color: #5546ff;
                    width: 100%;
                    opacity: 1;

                    @include max-breakpoint(760px) {
                        width: 0;
                    }
                }

                h2 {
                    color: #3b3b3a;

                    @include max-breakpoint(760px) {
                        color: #fff;
                    }
                }

                @include max-breakpoint(760px) {
                    border: none;
                }
            }

            &.__active {

                &:before {
                    opacity: 1;
                    transition: opacity 0.2s ease;
                }
            }

            & p {
                position: relative;
            }
        }
    }
}

.header-row {
    height: 90px;
    width: 100%;
    max-width: $layoutWidth;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
    min-width: 180px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: white;

    @include max-breakpoint(760px) {
        height: $headerHeight - 40px;
    }

    @include max-breakpoint($layoutWidth) {
        padding: 0 2% 0 0;
    }

    @include max-breakpoint(760px) {
        justify-content: space-between;
    }

    a.header--logo {
        border: 0;
        z-index: 1;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 2%;
        padding-right: 2%;
        position: relative;
        overflow: hidden;

        @include max-breakpoint(1250px) {
            margin-right: 5px;
        }

        @include max-breakpoint(480px) {
            margin-right: 0;
        }

        &:hover {
            border: 0;
        }
    }

    a.logo--patron {
        border: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @include max-breakpoint(480px) {
            flex-direction: column;
            height: 100%;
            justify-content: space-around;
            align-items: flex-start;
        }

        .logo--desc {
            color: #000;
            font-size: 12px;
            text-transform: uppercase;
            padding-right: 8px;
            letter-spacing: 1px;

            @include max-breakpoint(480px) {
                font-size: 11px;
            }
        }

        > img {
            @include max-breakpoint(480px) {
                max-height: 60%;
            }
        }
    }
}

.header-hamburger {
    display: none;

    span {
        background-color: $domain-color;
        border-radius: 2px;
        width: 30px;
        height: 4px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &:nth-of-type(1) {
            top: calc(50% - 10px);
        }

        &:nth-of-type(3) {
            top: calc(50% + 10px);
        }
    }
}

@include max-breakpoint(760px) {
    .header {
        .wrapper {
            position: static;
        }

        .header-row {
            padding: 0 10px 0 0;

            .header--logo {
                img {
                    height: 100%;
                    object-fit: contain;
                    width: 90%;
                    max-height: 50%;

                    @include max-breakpoint(500px) {
                        width: 90%;
                    }

                    @include max-breakpoint(390px) {
                        width: 110px;
                    }
                }
            }
        }

        .header-hamburger {
            display: block;
            position: absolute;
            clear: both;
            content: " ";
            background-size: 100%;
            top: 50%;
            transform: translateY(-50%);
            right: 140px;
            width: 60px;
            border-radius: 7px;
            height: 70%;
            cursor: pointer;
            z-index: 2;
            transition: all .62s ease;

            @include max-breakpoint(430px) {
                right: 125px;
            }

            & img {
                width: 30%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

            }
        }

        &.__shrinked .header-hamburger {
            right: 16px;
        }

        &.__shrinked nav.header--nav {
            width: calc(92vw - 170px);
        }

        .header-hamburger.__js_isActive {
            display: block;

            & img {
                width: 30%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

            }
        }

        //nawigacja na mobile
        nav.header--nav {
            position: absolute;
            right: -100%;
            top: 100%;
            width: 33%;
            min-width: 270px;
            overflow: hidden;
            height: auto;
            z-index: 1;
            opacity: 0;
            transition: all 0.3s ease;
            will-change: right, opacity;

            @include max-breakpoint(600px) {
                width: 100%;
            }
        }

        nav.header--nav.__js_isActive {
            opacity: 1;
            text-align: center;
            right: 0;
        }

        nav.header--nav {
            flex-direction: column;
            text-align: center;

            a {
                margin: 0 auto;
                display: block;
                clear: both;
                color: black;
                padding: 0;
                position: relative;
                background-color: $domain-color;


                &:hover {
                    background-color: #efeef6;

                    h2 {
                        color: #5546e0;
                        ;
                    }

                    &:before {
                        display: block;
                    }
                }

                &.__active {
                    border-bottom: 0;
                }
            }

            a.logo--patron {
                display: block;
                padding: 0;
                top: 0;
                padding-top: 4%;
                box-sizing: content-box;
                position: relative;
                margin-bottom: 3%;
                border-bottom: 0;
                float: none;
            }
        }

        &.active nav.header--nav {
            right: 0;
            text-align: center;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        }
    }
}

@include max-breakpoint($point2) {
    .header .wrapper nav.header--nav {
        flex-direction: column;

        @media (orientation: landscape) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            align-content: stretch;
            max-width: 100%;
            width: 100%;

            a:before {
                width: 100%;
                left: 0;
                transform: none;
                bottom: 0;
            }
        }
    }
}

.header--logo-main {
    display: flex;
    align-items: center;
    order: 1;
    position: absolute;
    right: 2%;

    @include max-breakpoint(760px) {
        margin-right: 10px;
        transform: scale(0.9);
    }

    & .header--logo-partner {
        color: #75604e;
        text-transform: uppercase;
        max-width: 70px;
        margin-right: 20px;

        @include max-breakpoint(760px) {
            margin-top: 0px;
            font-size: 18px;
            margin-right: 25px;
        }
    }

    & .logo--wp {
        display: flex;
        width: 100%;
        align-items: flex-start;
        flex-direction: column;

        p {
            text-transform: uppercase;
            color: #5546ff;
            font-size: 13px;
            border: 2px solid #e5e5e9;
            padding: 5px 10px;
            font-family: 'Jost', sans-serif;
        }

        & img {
            @include max-breakpoint(450px) {
                width: 105px;
            }
        }
    }
}
