.list {
    background-color: #fff !important;

    .wrapper {
        display: flex;
        justify-content: center;
        // padding: 50px 20px;

        @include max-breakpoint (700px) {
            display: block;
        }

        .title {
            color: #3b3b3a;
            font-family: $font-title;
            font-weight: bold;
        }

        // .container {
        //     display: flex;
        //     flex-wrap: wrap;
        //     justify-content: flex-end;
        //     margin-top: 40px;

        //     @include max-breakpoint (920px) {
        //         width: 83%;
        //     }

        //     @include max-breakpoint (700px) {
        //         width: 100%;
        //         justify-content: center;
        //         margin-top: 25px;
        //     }

        ul {
            display: flex;
            width: 70%;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-top: 50px;
            margin-left: 40px;

            @include max-breakpoint (700px) {
                width: 100%;
                margin-left: 10px;
                margin-top: 25px;
            }

            li {
                width: calc(33% - 33px);
                color: #3b3b3a;
                position: relative;
                margin-bottom: 20px;
                padding-left: 24px;
                margin-right: 10px;

                @include max-breakpoint (510px) {
                    width: 46%;
                }

                @include max-breakpoint (400px) {
                    width: 100%;
                }

                span {
                    font-weight: bold;
                }

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 5px;
                    width: 19px;
                    height: 10px;
                    background-image: url(https://wpartner-2020.wpcdn.pl/img/list-img.png);
                    background-size: cover;
                }
            }
        }
    }
}