.target {
    background-color: #efeef6 !important;

    .wrapper {
        padding: 40px 20px;
        padding-bottom: 100px;

        .title {
            font-family: $font-title;
            font-weight: bold;
        }

        .container {
            display: flex;
            justify-content: center;
            margin-top: 10px;

            .img {
                width: 379px;
                height: 280px;
                position: relative;

                @include max-breakpoint (850px) {
                    width: 200px;
                    height: 170px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            p {
                position: absolute;
                width: 200px;
                color: #000000;
                font-size: 15px;

                span {
                    color: #5546ff;
                }

                &:nth-of-type(1) {
                    left: -10px;
                    top: 50%;
                    transform: translate(-100%, -70%);
                }

                &:nth-of-type(2) {
                    right: -10px;
                    top: 50%;
                    transform: translate(100%, -70%);
                }

                &:nth-of-type(3) {
                    right: 50%;
                    transform: translate(50%, 0%);
                    text-align: center;
                }
            }
        }
    }
}