.hover_bkgr_fricc {
    background: rgba(0, 0, 0, .2);
    cursor: pointer;
    display: none;
    height: 100%;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 10000;
}

.hover_bkgr_fricc .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.hover_bkgr_fricc>div {
    background-color: #fff;
    box-shadow: 10px 10px 60px #555;
    display: inline-block;
    height: auto;
    max-width: 551px;
    min-height: 80px;
    vertical-align: middle;
    width: 180px;
    position: relative;
    border-radius: 8px;
    padding: 15px 5%;

    p {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-height: 90%;
        overflow: hidden;
        width: 85%;
    }
}

.popupCloseButton {
    background-color: #fff;
    border: 3px solid #999;
    border-radius: 50px;
    cursor: pointer;
    display: inline-block;
    font-family: arial;
    font-weight: bold;
    position: absolute;
    top: -14px;
    right: -14px;
    font-size: 25px;
    line-height: 30px;
    width: 30px;
    height: 30px;
    text-align: center;
    color: #5243ff;
}

p {
    color: black;
}

.popupCloseButton:hover {
    background-color: #ccc;
}

.trigger_popup_fricc {
    cursor: pointer;
    font-size: 20px;
    margin: 20px;
    display: inline-block;
    font-weight: bold;
}